import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form } from "formik";

import { ContractsApi } from '../../../apis/ContractsApi';
import CreateNotification from '../../Core/Notification/Notification';

export function AddPaymentModal(props) {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const addPayment = async (values) => {
        values = {
            contractId: props.contractId,
            ...values
        }

        const response = await ContractsApi.AddPayment(values);

        if (!response.error) {
            CreateNotification('success', 'Pagamento adicionado com sucesso!');
        } else {
            const { errors } = response;
            CreateNotification('error', errors);
        }
    };

    return (
        <>
            <button className="btn-custom" onClick={handleShow}>
                Adicionar
            </button>

            <Modal show={show} onHide={handleClose} size="sm" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Pagamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            lastPayment: new Date().toISOString().slice(0, 10),
                            formPayment: "Pix",
                        }}
                        onSubmit={addPayment}
                    >
                        <Form className="mb-3">
                            <div className="form-label-group mb-3">
                                <label htmlFor="formPayment">
                                    Forma de Pagamento
                                </label>

                                <Field
                                    name="formPayment"
                                    className="form-control"
                                    type="text"
                                />
                            </div>

                            <div className="form-label-group mb-3">
                                <label htmlFor="lastPayment">
                                    Data Pagamento
                                </label>

                                <Field
                                    name="lastPayment"
                                    className="form-control"
                                    type="date"
                                />
                            </div>

                            <button type="submit" className="btn-custom w-100">
                                Adicionar
                            </button>
                        </Form>
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}
