import api from '../infra/api';

export class ContractsApi {
    static Get = async () => {
        const response = await api.get('contracts').catch((error) => {
            return error.response;
        });

        return response.data;
    }

    static Post = async (contract) => {
        const response = await api.post('contracts', contract).catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static Put = async (contract) => {
        const response = await api.put('contracts', contract).catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static Delete = async (id) => {
        const response = await api
            .delete(`contracts?contractId=${id}`)
            .catch((error) => {
                return error.response;
            });

        return response.data;
    };

    static AddPayment = async (payment) => {
        const response = await api.post('contracts/add-payment', payment)
            .catch((error) => {
                return error.response;
            });

        return response.data;
    };
}