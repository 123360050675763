import React, { useState, useEffect } from 'react';
import BlockUi from 'react-block-ui';

import { UsersApi } from '../../apis/UsersApi';
import { UtilService } from '../../utils/Util';
import { UserValidator } from '../../validators/UserValidator';

import CreateNotification from '../Core/Notification/Notification';
import { GetTokenInfo, getToken } from '../../utils/Auth';

export default function MyAccount() {
    const [user, setUser] = useState({ daysOfWeekToNotImport: [] });
    const [contract, setContract] = useState({});
    const [onRequest, setOnRequest] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value.trim();
        setUser({ ...user, [name]: value });
    };

    const handleCheck = (event) => {
        const target = event.target;
        const name = target.name;
        setUser({ ...user, [name]: target.checked });
    };

    const handleSelect = (event) => {
        const target = event.target;;
        const name = target.name;

        const selectedValues = document.querySelectorAll(`select[name='${name}'] option:checked`);
        const values = Array.from(selectedValues).map((el) => parseInt(el.value));

        setUser({ ...user, [name]: values });
    }

    useEffect(() => {
        setOnRequest(true);

        const userId = GetTokenInfo(getToken()).UserId;

        UsersApi.Get(userId).then((response) => {
            const { data } = response;
            setUser(data.user);
            setContract(data.contract);
        });

        setOnRequest(false);
    }, []);

    const save = async (e) => {
        e.preventDefault();

        const isValid = UserValidator.IsValid(user);

        if (!isValid) return;

        setOnRequest(true);

        const userViewModel = createUser();
        const response = await UsersApi.Put(userViewModel);

        if (!response.error) {
            CreateNotification('success', 'Dados alterados.');
        } else {
            CreateNotification('error', response.errors);
        }

        setOnRequest(false);
    };

    const createUser = () => {
        return {
            ...user,
            qntDaysInActive: user.qntDaysInActive ? parseInt(user.qntDaysInActive) : null
        };
    };

    return (
        <div className="container">
            <BlockUi tag="div" blocking={onRequest}>
                <div className="card form-custom mt-4">
                    <div className="p-5">
                        <h4 className="text-center mb-4">Minha Conta</h4>
                        <form>
                            <div className="row form-group mb-3">
                                <div className="col-md-3">
                                    <label className="label" htmlFor="name">
                                        Nome
                                    </label>

                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control form-control-sm"
                                        autoFocus
                                        required
                                        onChange={handleChange}
                                        defaultValue={user.name}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <label className="label" htmlFor="email">
                                        Email
                                    </label>

                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control form-control-sm"
                                        required
                                        onChange={handleChange}
                                        defaultValue={user.email}
                                    />
                                </div>
                            </div>

                            <div className="row form-group mb-3">
                                {
                                    user.accessToken ?
                                        <div className="col-md-3">
                                            <label
                                                className="label"
                                                htmlFor="importTime1"
                                            >
                                                Horários de Publicação:
                                            </label>

                                            <input
                                                type="text"
                                                name="importTime"
                                                className="form-control form-control-sm"
                                                onChange={handleChange}
                                                defaultValue={user.importTime}
                                            />
                                        </div>
                                        : ''
                                }

                                <div className="col-md-3">
                                    <label
                                        className="label"
                                        htmlFor="qntDaysInActive"
                                    >
                                        Qtd dias de anúncios ativos:
                                    </label>

                                    <input
                                        type="number"
                                        name="qntDaysInActive"
                                        className="form-control form-control-sm"
                                        onKeyUp={handleChange}
                                        defaultValue={user.qntDaysInActive}
                                    />
                                </div>
                            </div>

                            <div className="form-check col-md-6">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="randomizeImages"
                                    onClick={handleCheck}
                                    defaultChecked={user.randomizeImages}
                                />

                                <label
                                    className="form-check-label"
                                    htmlFor="randomizeImages"
                                >
                                    Alterar imagem de capa na publicação
                                </label>
                            </div>


                            <div className="row mt-3 mb-3">
                                <label
                                    className="label"
                                    htmlFor="daysOfWeekToNotImport"
                                >
                                    Dias da Semana para não publicar:
                                </label>

                                <select
                                    multiple
                                    name="daysOfWeekToNotImport"
                                    className="form-control"
                                    size="7"
                                    onChange={handleSelect}
                                    value={user.daysOfWeekToNotImport}
                                >
                                    <option value="1">Segunda-Feira</option>
                                    <option value="2">Terça-Feira</option>
                                    <option value="3">Quarta-Feira</option>
                                    <option value="4">Quinta-Feira</option>
                                    <option value="5">Sexta-Feira</option>
                                    <option value="6">Sábado</option>
                                    <option value="0">Domingo</option>
                                </select>
                            </div>

                            <div className="row mb-3 mt-3">
                                <span className="col-md-6">
                                    <b>Último Pagamento: </b>
                                    {contract && UtilService.dateFormat(contract.lastPayment)}
                                </span>

                                <span className="col-md-6">
                                    <b>Próximo Pagamento: </b>
                                    {contract && UtilService.dateFormat(
                                        UtilService.getNextPayment(
                                            contract.lastPayment,
                                            contract.evaluationPeriod ? 3 : 30,
                                        ),
                                    )}
                                </span>
                            </div>

                            <div className="row mb-3">
                                <span className="col-md-6">
                                    <b>Url CanalPro: </b>
                                    {user.id ? (
                                        window.location.protocol + "//" + window.location.host + "/api/CanalPro?userId=" + user.id
                                    ) : ("")}
                                </span>
                            </div>

                            <div className="row">
                                <button
                                    className="btn-custom ml-3"
                                    onClick={save}
                                >
                                    Salvar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </BlockUi>
        </div>
    );
}
