import api from '../infra/api';

export class FilesApi {
    static SendToS3 = async (files) => {
        const data = new FormData();

        if (files.length <= 0) {
            return [];
        }

        files.forEach((f) => {
            const file = new Blob([f], { type: 'image/png' });
            data.append(f.name, file, f.name);
        });

        const upload = await api
            .post('files', data, {
                headers: { 'content-type': 'multipart/form-data' },
            })
            .catch((error) => {
                return error.response;
            });

        return upload.data;
    };

    static GetPreSignedUrl = async (keys) => {
        const response = await api.post('files', keys).catch((error) => {
            return error.response;
        });

        return response.data;
    };
}
