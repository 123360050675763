import React, { useEffect, useState } from 'react';

import { FaBath, FaBed, FaCarSide } from 'react-icons/fa';
import { UtilService } from '../../../utils/Util';

export default function MiniAdCard(props) {
    const product = props.product;
    const [locationData, setLocationData] = useState({ bairro: '', localidade: '' });

    const openUrl = () => {
        const url = `/anuncio/${product.id}`;

        window.open(url);
    };

    useEffect(() => {
        UtilService.GetZipCodeInformations(product.zipcode).then((response) => {
            setLocationData(response);
        });
    }, [product?.zipcode]);

    return (
        <div className="card adCard mt-2 ml-3" style={{ cursor: 'pointer' }} onClick={openUrl} >
            <div className="row">
                <img
                    src={product.images[0]}
                    className="containerImage"
                    alt="anuncio"
                />
            </div>

            <div className="row">
                <div className="col-xs-8 mt-2 ml-2">
                    <div className="title">
                        <b>{product.subject}</b>
                    </div>

                    <div>
                        <FaBed /> {product.rooms}
                        <FaBath className="ml-2" /> {product.bathrooms}
                        <FaCarSide className="ml-2" /> {product.garage_spaces}
                    </div>

                    <div className="title">
                        {locationData?.bairro ? `${locationData?.bairro}, ${locationData?.localidade}`
                            : `CEP: ${product.zipcode}`}
                    </div>

                    <div className="title">
                        R$ {parseInt(product?.price).toLocaleString('pt-BR')}
                    </div>
                </div>
            </div>
        </div>
    );
}
