import React, { useState } from 'react';
import BlockUi from 'react-block-ui';

import { ProductParameters } from './ProductParameters';

import { LogsApi } from '../../../apis/LogsApi';
import { ProductsApi } from '../../../apis/ProductsApi';

import { UploadService } from '../../../services/UploadService';

import { UploadImages } from '../../Core/UploadImages/UploadImages';
import CreateNotification from '../../Core/Notification/Notification';
import { ProductValidator } from '../../../validators/ProductValidator';

export function ProductForm(props) {
    const [state, setState] = useState({
        rooms: 0,
        images: [],
        features: [],
        bathrooms: 1,
        category: 1020,
        productType: 1,
        garageSpaces: 0,
        active: true,
        ...props.product,
    });

    const [onRequest, setOnRequest] = useState(false);

    const objectFiles = state.images && state.images.length > 0 ?
        state.images.map((image) => {
            return {
                name: image,
                src: image,
            };
        }) : [];

    const [files, setFiles] = useState(objectFiles);

    const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;

        if (name === 'category') {
            value = parseInt(value);
        }

        setState({ ...state, [name]: value });
    };

    const handleCheck = (event) => {
        const target = event.target;
        const name = target.name;

        setState({ ...state, [name]: target.checked });
    };

    const createProduct = async () => {
        const newImages = await UploadService.UploadImages(files, state.images);
        const featuresSelected = document.querySelectorAll("select[name='features'] option:checked");

        const oldImages = files.filter((file) => file.src != null && file.src !== undefined).map((file) => file.src);

        return {
            id: state.id,
            body: state.body,
            subject: state.subject,
            images: oldImages.concat(newImages),
            phone: parseInt(state.phone),
            category: parseInt(state.category),
            productType: parseInt(state.productType),
            size: state.size ? parseInt(state.size) : null,
            price: state.price ? parseInt(state.price) : null,
            rooms: state.rooms ? parseInt(state.rooms) : null,
            bathrooms: state.bathrooms ? parseInt(state.bathrooms) : null,
            condominio: state.condominio ? parseInt(state.condominio) : null,
            garageSpaces: state.garageSpaces ? parseInt(state.garageSpaces) : null,
            features: Array.from(featuresSelected).map((el) => parseInt(el.value)),
            quantityPremium: !state.quantityPremium ? null : parseInt(state.quantityPremium),
            quantitySuperPremium: !state.quantitySuperPremium ? null : parseInt(state.quantitySuperPremium),
            video: state.video,
            active: state.active,
            constructionYear: state.constructionYear,
            iptu: state.iptu,
            location: {
                city: state.city ?? state.location?.city ?? null,
                zone: state.zone ?? state.location?.zone ?? null,
                neighborhood: state.neighborhood ?? state.location?.neighborhood ?? null,
                address: state.address ?? state.location?.address ?? null,
                streetNumber: state.streetNumber ?? state.location?.streetNumber ?? null,
                complement: state.complement ?? state.location?.complement ?? null,
                zipcode: state.zipcode ?? state.location?.zipcode ?? null,
            }
        };
    };

    const saveProduct = async (e) => {
        try {
            e.preventDefault();

            if (!ProductValidator.IsValid(state)) {
                return;
            }

            setOnRequest(true);

            const product = await createProduct();
            const response = !state.id ? await ProductsApi.Post(product) : await ProductsApi.Put(product);

            setOnRequest(false);

            if (!response.error) {
                CreateNotification('success', 'Anúncio salvo com sucesso!');
            } else {
                const { errors } = response;
                CreateNotification('error', errors, 'Erro ao salvar anúncio.', null, () => null);
            }
        } catch (exception) {
            await LogsApi.Post("[WebApp] Error", "saveProduct", JSON.stringify(exception));
            CreateNotification('error', exception.stack, exception.message, null, () => null);
        }
    };

    return (
        <form>
            <BlockUi tag="div" blocking={onRequest} message="Salvando os dados..." >
                <div className="form-check mb-3">
                    <input
                        type="checkbox"
                        id="active"
                        name="active"
                        className="form-check-input"
                        onChange={handleCheck}
                        defaultChecked={state.active}
                    />

                    <label htmlFor="active" className="form-check-label">Ativo</label>
                </div>

                <div className="form-label-group mb-3">
                    <label htmlFor="subject">Título*</label>
                    <input
                        type="text"
                        id="subject"
                        name="subject"
                        className="form-control"
                        autoFocus
                        required
                        onChange={handleChange}
                        defaultValue={state.subject}
                        minLength="2"
                        maxLength="90"
                    />
                </div>

                <div className="form-label-group mb-3">
                    <label htmlFor="body">Corpo do anúncio*</label>
                    <textarea
                        id="body"
                        name="body"
                        rows="5"
                        className="form-control"
                        required
                        onChange={handleChange}
                        defaultValue={state.body}
                        minLength="2"
                        maxLength="6000"
                    />
                </div>

                <div className="row md-3">
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="phone">Telefone*</label>
                        <input
                            type="number"
                            id="phone"
                            name="phone"
                            className="form-control"
                            placeholder="Ex.: 79998745632"
                            required
                            onChange={handleChange}
                            defaultValue={state.phone}
                        />
                    </span>

                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="zipcode">CEP*</label>
                        <input
                            type="number"
                            id="zipcode"
                            name="zipcode"
                            className="form-control"
                            placeholder="Sem hífen - Ex.: 49030550"
                            required
                            onChange={handleChange}
                            defaultValue={state.location?.zipcode}
                        />
                    </span>
                </div>

                <div className="row md-3">
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="streetNumber">Número do Endereço*</label>
                        <input
                            type="text"
                            max="5"
                            id="streetNumber"
                            name="streetNumber"
                            className="form-control"
                            placeholder="Ex.: 147"
                            onChange={handleChange}
                            defaultValue={state.location?.streetNumber}
                        />
                    </span>

                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="complement">Complemento*</label>
                        <input
                            type="text"
                            id="complement"
                            name="complement"
                            className="form-control"
                            required
                            onChange={handleChange}
                            defaultValue={state.location?.complement}
                        />
                    </span>
                </div>

                <div className="row md-3">
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="city">Cidade</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="form-control"
                            placeholder="Ex.: Aracaju"
                            onChange={handleChange}
                            defaultValue={state.location?.city}
                        />
                    </span>

                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="zone">Zona</label>
                        <input
                            type="text"
                            id="zone"
                            name="zone"
                            className="form-control"
                            placeholder="Ex.: Zona Sul"
                            onChange={handleChange}
                            defaultValue={state.location?.zone}
                        />
                    </span>
                </div>

                <div className="row md-3">
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="neighborhood">Bairro*</label>
                        <input
                            type="text"
                            id="neighborhood"
                            name="neighborhood"
                            className="form-control"
                            placeholder="Ex.: Farolândia"
                            required
                            onChange={handleChange}
                            defaultValue={state.location?.neighborhood}
                        />
                    </span>

                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="address">Endereço*</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            className="form-control"
                            placeholder="Ex.: Rua Sol Nascente"
                            required
                            onChange={handleChange}
                            defaultValue={state.location?.address}
                        />
                    </span>
                </div>

                <div className="row md-3">
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="price">Preço</label>
                        <input
                            type="number"
                            id="price"
                            name="price"
                            className="form-control"
                            placeholder="Preço sem centavos - Ex.: 150000"
                            min="1"
                            onChange={handleChange}
                            defaultValue={state.price}
                        />
                    </span>

                    {state.category !== 1060 ? (
                        <span className="form-label-group mb-3 col-md-6">
                            <label htmlFor="size">Tamanho do imóvel - m²*</label>
                            <input
                                type="number"
                                id="size"
                                name="size"
                                className="form-control"
                                placeholder="Em metros quadrados"
                                min="1"
                                required
                                onChange={handleChange}
                                defaultValue={state.size}
                            />
                        </span>
                    ) : (
                        ''
                    )}
                </div>

                <ProductParameters product={state} handleChange={handleChange} />

                <div className="row md-3">
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="quantitySuperPremium">Super Destaque</label>
                        <input
                            type="number"
                            id="quantitySuperPremium"
                            name="quantitySuperPremium"
                            className="form-control"
                            placeholder="Quantidade de anúncios com Super Destaque - Ex.: 5"
                            min="1"
                            onChange={handleChange}
                            defaultValue={state.quantitySuperPremium}
                        />
                    </span>

                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="quantityPremium">Destaques</label>
                        <input
                            type="number"
                            id="quantityPremium"
                            name="quantityPremium"
                            className="form-control"
                            placeholder="Quantidade de anúncios com destaque - Ex.: 15"
                            min="1"
                            onChange={handleChange}
                            defaultValue={state.quantityPremium}
                        />
                    </span>
                </div>

                <div className="row md-3">
                    <span className="form-label-group mb-3 col-md-12">
                        <label htmlFor="video">Video</label>
                        <input
                            type="text"
                            id="video"
                            name="video"
                            className="form-control"
                            placeholder="Link do video do youtube. Ex.: https://www.youtube.com/watch?v=R-123456"
                            onChange={handleChange}
                            defaultValue={state.video}
                        />
                    </span>
                </div>

                <UploadImages files={files} setFiles={setFiles} images={objectFiles} />

                <button
                    className="btn-custom d-block mt-3 w-100"
                    style={{ fontSize: '15px' }}
                    type="submit"
                    onClick={saveProduct}
                >
                    Salvar
                </button>
            </BlockUi>
        </form>
    );
}
