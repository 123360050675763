import * as Yup from 'yup';

import { UtilService } from "../utils/Util";

export default class YupValidations {
    static cpfCnpjValidate() {
        return Yup.string()
            .required('CPF/CNPJ é obrigatório')
            .test('cpf-cnpj-valido', 'CPF/CNPJ inválido',
                (value) => value.replace(/[^\d]+/g, '').length === 11 ? UtilService.cpfValidate(value) : UtilService.cnpjValidate(value))
    }

    static emailValidate() {
        return Yup.string()
            .required('Email é obrigatório')
            .test('email-valido', 'Email inválido',
                (value) => UtilService.emailValidate(value));
    }

    static cepValidate() {
        return Yup.string()
            .required('CEP é obrigatório')
            .test('cep-valido', 'CEP inválido',
                (value) => UtilService.cepValidate(value));
    }

    static phoneValidate() {
        return Yup.string()
            .required('Telefone é obrigatório')
            .test('phone-valido', 'Telefone inválido',
                (value) => UtilService.phoneValidate(value));
    }
}
