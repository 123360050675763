import React from 'react';
import './styles/Catalog.css';

import {ProductForm} from './components/ProductForm';

export function NewProduct() {
    return (
        <div className="container">
            <div className="mt-4 mb-3">
                <div className="form-custom card card-signin">
                    <div className="card-body">
                        <h5 className="card-title text-center text-black-50">
                            Cadastrar Novo Anúncio
                        </h5>

                        <ProductForm product={{}}></ProductForm>
                    </div>
                </div>
            </div>
        </div>
    );
}
