import api from '../infra/api';

export class OlxApi {
    static RemoveAdByQuantity = async (quantity) => {
        const response = await api
            .delete(`olx/remove-ad-by-quantity?quantity=${quantity}`)
            .catch((error) => {
                return error.response;
            });

        return response.data;
    };
}