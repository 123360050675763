import CreateNotification from '../views/Core/Notification/Notification';
import { UtilService } from '../utils/Util';

export class ProductValidator {
    static IsValid = (product) => {
        if (!product.subject) {
            CreateNotification('error', 'Necessário preencher o título do anúncio.');
            return false;
        }

        if (!product.body) {
            CreateNotification('error', 'Necessário preencher o corpo do anúncio.');
            return false;
        }

        if (!product.phone || product.phone.toString().length < 10 || product.phone.toString().length > 11) {
            CreateNotification('error', 'Telefone inválido! Mínimo de 10 e máximo de 11 caracteres. Enviar DDD + Telefone sem caracteres especiais ou espaços.');
            return false;
        }

        const zipcode = product.zipcode ?? product?.location?.zipcode ?? null;
        if (!zipcode || zipcode.toString().length !== 8 || zipcode === "49000000") {
            CreateNotification('error', 'CEP inválido! São necessários 8 caracteres.');
            return false;
        }

        const streetNumber = product.streetNumber ?? product?.location?.streetNumber ?? null;
        if (!streetNumber || streetNumber.toString().length === 0) {
            CreateNotification('error', 'Número do endereço é obrigatório.');
            return false;
        }

        const complement = product.complement ?? product?.location?.complement ?? null;
        if (!complement || complement.toString().length === 0) {
            CreateNotification('error', 'Complemento é obrigatório.');
            return false;
        }

        if (product.price) {
            if (!UtilService.IntegerValid(product.price)) {
                CreateNotification('error', 'Preço inválido! Não é possível utilizar vírgulas ou ponto.');
                return false;
            }
        }

        if (!product.size) {
            CreateNotification('error', 'Tamanho do imóvel é obrigatório.');
            return false;
        }

        if (product.size) {
            if (!UtilService.IntegerValid(product.size)) {
                CreateNotification('error', 'Tamanho do imóvel inválido! Não é possível utilizar vírgulas ou ponto.');
                return false;
            }
        }

        if (product.condominio) {
            if (!UtilService.IntegerValid(product.condominio)) {
                CreateNotification('error', 'Valor do condomínio inválido! Não é possível utilizar vírgulas ou ponto.');
                return false;
            }
        }

        const needRooms = [1020, 1040, 1080];
        if ((!product.rooms || product.rooms === 0) && needRooms.includes(product.category)) {
            CreateNotification('error', 'Necessário preencher a quantidade de quartos.');
            return false;
        }

        if (product.video) {
            if (!product.video.includes("youtube") && !product.video.includes("youtu")) {
                CreateNotification('error', 'Somente serão processados os vídeos armazenados no Youtube.');
                return false;
            }
        }

        if (product.constructionYear) {
            if (!UtilService.IntegerValid(product.constructionYear)) {
                CreateNotification('error', 'Ano inválido! Não é possível utilizar vírgulas ou ponto.');
                return false;
            }
        }

        return true;
    };
}
