import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form } from "formik";

import { UtilService } from '../../../utils/Util';
import { ContractsApi } from '../../../apis/ContractsApi';
import CreateNotification from '../../Core/Notification/Notification';

export function ContractModal(props) {
    const contract = { ...props.contract };
    const users = props.users;

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {

    }, [])

    const saveContract = async (values) => {
        let response;
        let message;

        values = {
            ...contract,
            ...values
        }

        if (contract.id) {
            response = await ContractsApi.Put(values);
            message = 'Contrato atualizado com sucesso!';
        } else {
            response = await ContractsApi.Post(values);
            message = 'Contrato criado com sucesso!';
        }

        if (!response.error) {
            CreateNotification('success', message);
        } else {
            CreateNotification('error', response.errors);
        }
    }

    return (
        <>
            <button className="btn-custom" type='button' onClick={handleShow}>
                {contract.id ? 'Editar' : 'Novo'}
            </button>

            <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {contract.id ? 'Editar Contrato' : 'Novo Contrato'}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Formik
                        initialValues={{
                            name: contract?.name ? contract.name : "",
                            lastPayment: contract?.lastPayment ?
                                new Date(contract.lastPayment).toISOString().slice(0, 10)
                                : new Date().toISOString().slice(0, 10),
                            managerUserId: contract?.managerUserId ? contract.managerUserId : "",
                            managedUserIds: contract?.managedUserIds ? contract.managedUserIds : [],
                            value: contract?.value ? contract.value : 0,
                            observations: contract?.observations ? contract.observations : "",
                            status: !UtilService.isEmpty(contract) ? contract.status : true,
                            evaluationPeriod: !UtilService.isEmpty(contract) ? contract.evaluationPeriod : true,
                        }}
                        onSubmit={saveContract}
                    >
                        <Form className="mb-3">
                            <div className="form-label-group mb-3">
                                <label htmlFor="name">
                                    Nome
                                </label>

                                <Field
                                    name="name"
                                    className="form-control"
                                    type="text"
                                />
                            </div>

                            <div className="form-label-group mb-3">
                                <label htmlFor="lastPayment">
                                    Data Pagamento
                                </label>

                                <Field
                                    name="lastPayment"
                                    className="form-control"
                                    type="date"
                                />
                            </div>

                            <div className="form-label-group mb-3">
                                <label htmlFor="managerUserId">
                                    Usuário Gestor
                                </label>
                                <Field
                                    name="managerUserId"
                                    className="form-control"
                                    component="select"
                                    multiple={false}
                                >
                                    <option key="0" value="">Selecione</option>
                                    {users && users.map(user => (
                                        <option key={user.id} value={user.id}>{user.name}</option>
                                    ))}
                                </Field>
                            </div>

                            <div className="form-label-group mb-3">
                                <label htmlFor="managedUserIds">
                                    Usuários Gerenciados
                                </label>
                                <Field
                                    name="managedUserIds"
                                    className="form-control"
                                    component="select"
                                    multiple={true}
                                    style={{ height: '150px' }}
                                >
                                    {users && users.map(user => (
                                        <option key={user.id} value={user.id}>{user.name}</option>
                                    ))}
                                </Field>
                            </div>

                            <div className="form-label-group mb-3">
                                <label htmlFor="value">
                                    Valor
                                </label>
                                <Field
                                    name="value"
                                    className="form-control"
                                    type="number"
                                />
                            </div>

                            <div className="form-label-group mb-3">
                                <label htmlFor="observations">
                                    Observações
                                </label>
                                <Field
                                    name="observations"
                                    className="form-control"
                                    type="textarea"
                                />
                            </div>

                            <div className="form-check col-md-3">
                                <label htmlFor="status">
                                    <Field
                                        name="status"
                                        className="form-check-input"
                                        type="checkbox"
                                    />
                                    Ativo
                                </label>
                            </div>

                            <div className="form-check col-md-3">
                                <label htmlFor="evaluationPeriod">
                                    <Field
                                        name="evaluationPeriod"
                                        className="form-check-input"
                                        type="checkbox"
                                    />
                                    Período de Avaliação
                                </label>
                            </div>

                            <button type="submit" className="btn-custom w-100">
                                {contract.id ? 'Atualizar' : 'Criar'}
                            </button>
                        </Form>
                    </Formik>
                </Modal.Body>
            </Modal >
        </>
    );
}
