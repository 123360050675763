export const TOKEN_KEY = '@authenticator-Token';

export const getToken = () => localStorage.getItem(TOKEN_KEY);

function isTokenExpired(token) {
    const expiry = GetTokenInfo(token).exp;
    return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
}

export const isAuthenticated = () => {
    const token = getToken();
    const authenticated = token !== null && !isTokenExpired(token);

    if (!authenticated) {
        logout();
    }

    return authenticated;
}

export const isAdmin = () => {
    const token = getToken();
    const adminLogged = isAuthenticated() && parseInt(GetTokenInfo(token).role) === 0;
    return adminLogged;
}

export const isUserManager = () => {
    const token = getToken();
    const tokenInfo = GetTokenInfo(token);
    const contract = GetContract(tokenInfo);
    const adminLogged = isAuthenticated() && tokenInfo.UserId === contract.ManagerUserId;
    return adminLogged;
}

export const login = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
    if (getToken()) {        
        localStorage.removeItem(TOKEN_KEY);
        window.location.href = "/";
    }
};

export function GetTokenInfo(token) {
    if (token)
        return JSON.parse(atob(token.split('.')[1]));
    
    return null;
}

export const GetContract = (tokenInfo) => {
    if (!tokenInfo) {
        const token = getToken();
        tokenInfo = GetTokenInfo(token);
    }
    
    const contract = tokenInfo.Contract;
    if (!contract)
        logout();

    return JSON.parse(contract);
}
