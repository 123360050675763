import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import { ProductsApi } from '../../apis/ProductsApi';
import { UtilService } from '../../utils/Util';

import DefaultUser from '../../assets/default-user.png';
import MiniAdCard from './components/MiniAdCard';
import CreateNotification from '../Core/Notification/Notification';

export default function PublicAd() {
    const { id } = useParams();

    const [product, setProduct] = useState({ body: '', images: [] });
    const [user, setUser] = useState({});

    const [anotherProducts, setAnotherProducts] = useState([]);
    const [locationData, setLocationData] = useState({
        bairro: '',
        localidade: '',
    });

    const OpenWhatsapp = () => {
        const url = `https://wa.me/+55${product?.phone}`;
        window.open(url);
    };

    useEffect(() => {
        GetInformations();
    }, []);

    const GetInformations = () => {
        ProductsApi.GetPublicAd(id)
            .then((response) => {
                const { errors, data } = response;

                if (response.error) {
                    CreateNotification('error', errors);
                    return;
                }

                setProduct(data.product);
                setUser(data.user);
                setAnotherProducts(data.anotherProducts);

                UtilService.GetZipCodeInformations(data.product?.location?.zipcode)
                    .then((response) => {
                        setLocationData(response);
                    });
            })
            .catch((error) => {
                CreateNotification('error', error.message);
            });
    }

    return (
        <div className="container pt-5" style={{ color: '#615e5e' }}>
            <div className="card-title h3 d-flex justify-content-between">
                <p>{product?.subject}</p>

                <p>R$ {parseInt(product?.price).toLocaleString('pt-BR')}</p>
            </div>

            <div className="row">
                <div className="col-md-8">
                    <div className="card adCard">
                        <Carousel>
                            {product?.images.map((image) => (
                                <Carousel.Item key={image}>
                                    <img
                                        className="d-block w-100 rounded-top publicAdImage"
                                        src={image}
                                        alt="Slides"
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>

                        <div className="card-body">
                            {product?.body?.split("\n").map((text, idx) => {
                                return (
                                    <span key={idx}>
                                        {text}
                                        <br />
                                    </span>
                                )
                            })}

                            <hr />

                            <p className="card-text h5">
                                <b>Detalhes</b>
                            </p>

                            <p className="card-text">
                                <b>Categoria: </b>
                                {UtilService.ShowCategories(product?.category)}
                            </p>

                            <p className="card-text">
                                <b>Tipo: </b>
                                {UtilService.ShowProductType(
                                    product?.category,
                                    product?.productType,
                                )}
                            </p>

                            <p className="card-text">
                                <b>Preço:</b> R${' '}
                                {parseInt(product?.price).toLocaleString(
                                    'pt-BR',
                                )}
                            </p>

                            {product?.condominio && (
                                <p className="card-text">
                                    <b>Condominio: </b>
                                    R${' '}
                                    {parseInt(
                                        product?.condominio,
                                    ).toLocaleString('pt-BR')}
                                </p>
                            )}

                            {product?.garageSpaces && (
                                <p className="card-text">
                                    <b>Vagas na garagem: </b>
                                    {product?.garageSpaces}
                                </p>
                            )}

                            {product?.rooms && (
                                <p className="card-text">
                                    <b>Quartos: </b>
                                    {product?.rooms}
                                </p>
                            )}

                            {product?.bathrooms && (
                                <p className="card-text">
                                    <b>Banheiros: </b>
                                    {product?.bathrooms}
                                </p>
                            )}

                            {product?.size && (
                                <p className="card-text">
                                    <b>Área útil: </b>
                                    {product?.size} m²
                                </p>
                            )}

                            {product?.location?.zipcode && (
                                <p className="card-text">
                                    <b>CEP: </b>
                                    {locationData?.bairro && `${locationData.bairro}, `}
                                    {locationData?.localidade && `${locationData.localidade}, `}
                                    {product?.location?.zipcode}
                                </p>
                            )}

                            {product?.features && product?.features.length > 0 && (
                                <p className="card-text">
                                    <b>Detalhes do Imóvel: </b>
                                    {UtilService.ShowFeatures(
                                        product?.category,
                                        product?.features,
                                    ).join(', ')}
                                </p>
                            )}

                            <div className="d-flex justify-content-around"></div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 userCard">
                    <div className="card adCard">
                        <div className="card-title pt-3 text-center">
                            <img
                                className="rounded-circle w-25"
                                src={DefaultUser}
                                alt="user"
                            />
                        </div>

                        <div className="card-body">
                            <p className="text-center">
                                <b>{user?.name}</b>
                            </p>

                            <div className="text-center">
                                <button
                                    className="btn btn-custom w-75"
                                    onClick={OpenWhatsapp}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        fill="currentColor"
                                        className="bi bi-whatsapp"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                    </svg>

                                    <span className="ml-2">
                                        ENVIE UMA MENSAGEM
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <span className="h5">Sugestões de imóveis</span>
                    </div>

                    {anotherProducts.map((product) => (
                        <MiniAdCard key={product.id} product={product} />
                    ))}
                </div>
            </div>
        </div>
    );
}
