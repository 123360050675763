import React from 'react';
import CreateNotification from '../Core/Notification/Notification';

import { UsersApi } from '../../apis/UsersApi';

export default function AlterPassword() {
    const state = {
        password: '',
        confirmPassword: '',
    };

    const handleChange = (e) => {
        const name = e.target.name;
        state[name] = e.target.value;
    };

    const save = async (e) => {
        e.preventDefault();

        if (!state.password && !state.confirmPassword) {
            return;
        }

        if (state.password !== '' || state.confirmPassword !== '') {
            if (state.password !== state.confirmPassword) {
                CreateNotification('error', 'Senhas não coincidem!');
                return;
            }
        }

        const response = await UsersApi.AlterPassword(state.password);

        if (response && !response.error) {
            CreateNotification('success', 'Senha alterada com sucesso!');
        } else {
            CreateNotification('error', response.errors);
        }
    };

    return (
        <div className="container">
            <div className="card form-custom mt-4">
                <div className="p-5">
                    <h4 className="text-center mb-4">Alterar Senha</h4>
                    <form>
                        <div className="row form-group mb-3">
                            <div className="col-md-3">
                                <label className="label" htmlFor="password">
                                    Senha
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    className="form-control form-control-sm"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-3">
                                <label
                                    className="label"
                                    htmlFor="confirmPassword"
                                >
                                    Confirmação de senha
                                </label>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    className="form-control form-control-sm"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <button className="btn-custom ml-3" onClick={save}>
                                Alterar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
