import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { LogsApi } from '../../apis/LogsApi';

import { UtilService } from '../../utils/Util';

export default function LogSystem() {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        LogsApi.Get().then((response) => {
            setLogs(response.data);
        });
    }, []);

    return (
        <div className={logs.length > 0 ? 'mt-5 container card form-custom' : 'd-none'}>
            <h3 className="text-center mt-3">Logs de Execução</h3>

            <Table className="mt-2" responsive hover>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Tipo</th>
                        <th>Descrição</th>
                        <th>Estado</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log, index) => (
                        <tr key={index}>
                            <td>{UtilService.dateFormat(log.createdAt, false)}</td>
                            <td>{log.type}</td>
                            <td>{log.description}</td>
                            <td>
                                <div style={{ maxHeight: '100px', overflow: 'scroll' }}>
                                    {log.state}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
