import React, { useEffect, useState } from 'react';

import 'react-block-ui/style.css';
import BlockUi from 'react-block-ui';

import { ProductCard } from './components/ProductCard';
import Informations from '../Catalog/components/Informations';

import { ProductsApi } from '../../apis/ProductsApi';
import { UtilService } from '../../utils/Util';

export function ViewProducts() {
    const [onRequest, setOnRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [products, setProducts] = useState([]);
    const [currentProducts, setCurrentProducts] = useState([]);
    const [numberOfPublications, setNumberOfPublications] = useState(0);

    const [query, setQuery] = useState(null);
    const [percentagePage, setPercentagePage] = useState(0.2);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading) {
            return;
        }

        if (percentagePage >= 1)
            return;

        setPercentagePage(percentagePage + 0.2);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        if (!isLoading) {
            fillData();
        }

        filterCurrentProducts();

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, percentagePage, query]);

    const search = async (e) => {
        e.preventDefault();
        const queryValue = UtilService.removeDiacritics(e.target.value);
        setQuery(queryValue.toUpperCase());
    };

    const filterCurrentProducts = () => {
        if (!query) {
            setCurrentProducts(products.slice(0, getArrayEndSlice(products.length, percentagePage)));
            return;
        }

        setCurrentProducts(products.filter(p =>
            UtilService.removeDiacritics(p.subject).toUpperCase().includes(query) ||
            UtilService.removeDiacritics(p.status).toUpperCase().includes(query)
        ));
    }

    const fillData = async () => {
        if (products.length > 0)
            return;

        setOnRequest(true);
        setIsLoading(true);

        try {
            ProductsApi.Get().then((response) => {
                const { data } = response;

                if (data) {
                    setProducts(data.products);
                    setNumberOfPublications(data.publications);
                    setCurrentProducts(data.products.slice(0, getArrayEndSlice(data.products.length, percentagePage)));
                }
            });
        } catch (error) {
        } finally {
            setOnRequest(false);
            setIsLoading(false);
        }
    }
    
    const getArrayEndSlice = (productsLength, percentagePage) => {
        const end = Math.ceil(productsLength * percentagePage);
        if (end < 5)
            return 5;
        
        return end;
    }

    return (
        <div className="container">
            <BlockUi tag="div" blocking={onRequest} message="Carregando anúncios..." >
                <Informations products={products} publications={numberOfPublications} />

                <div className="input-group mt-4 mx-0">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                            Pesquisar
                        </span>
                    </div>

                    <input
                        type="text"
                        className="form-control"
                        placeholder="Digite o nome de um anúncio"
                        onChange={search}
                    />
                </div>

                <div className="row mt-4">
                    {currentProducts.map((product) => (
                        <div key={product.id} className="col-md-4 mb-4">
                            <ProductCard product={product} />
                        </div>
                    ))}
                </div>

                {
                    currentProducts.length < products.length ?
                        <div className='text-center'>
                            <button
                                className="btn-custom"
                                style={{ width: '120px' }}
                                onClick={() => setPercentagePage(percentagePage + 0.2)}
                            >
                                Carregar Mais Anúncios
                            </button>
                        </div>
                        : ''
                }

                {
                    products.length === 0 ?
                        <div className='row'>
                            Não há anúncios para exibir.
                        </div>
                        : ''
                }

            </BlockUi>
        </div>
    );
}
