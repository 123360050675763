import { NotificationManager } from 'react-notifications';

export default function CreateNotification(type, message, title, timeOut, callback, priority) {
    if (!timeOut) {
        timeOut = 2000;
    }

    if (!callback) {
        callback = () => window.location.reload();
    }

    if (!priority) {
        priority = 0;
    }

    switch (type) {
        case 'info':
            if (!title) {
                title = 'Info!';
            }

            NotificationManager.info(
                message,
                title,
                timeOut,
                callback,
                priority,
            );
            break;
        case 'success':
            if (!title) {
                title = 'Sucesso!';
            }

            setTimeout(() => window.location.reload(), timeOut);
            NotificationManager.success(
                message,
                title,
                timeOut,
                callback,
                priority,
            );
            break;
        case 'warning':
            if (!title) {
                title = 'Aviso!';
            }

            NotificationManager.warning(
                message,
                title,
                timeOut,
                callback,
                priority,
            );
            break;
        case 'error':
            if (!title) {
                title = 'Erro!';
            }

            NotificationManager.error(message, title, 4000, callback, priority);
            break;
        default:
            break;
    }
}
