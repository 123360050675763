import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';

import { ContractModal } from './components/ContractModal';
import { AddPaymentModal } from './components/AddPaymentModal';
import { DeleteContractModal } from './components/DeleteContractModal';

import { UtilService } from '../../utils/Util';
import { UsersApi } from '../../apis/UsersApi';
import { ContractsApi } from '../../apis/ContractsApi';

export default function Contracts() {
    const [contracts, setContracts] = useState([]);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        GetAllContracts();
        UsersApi.GetAll().then(response => {
            const { data } = response;
            UtilService.sort(data, 'name');
            setUsers(data);
        });
    }, [])

    const GetAllContracts = () => {
        ContractsApi.Get().then(res => {
            setContracts(res.data);
        });
    }

    return (
        <div className="mt-5 container card form-custom">
            <h3 className="text-center mt-3">Gerenciamento de Contratos</h3>

            <div className='d-flex justify-content-between'>
                <ContractModal users={users} />

                <span>
                    <b>Total: </b> {UtilService.curencyFormat(contracts.reduce((acc, contract) => acc + contract.value, 0))}
                </span>
            </div>

            <Table className="mt-2" responsive hover>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Último pagamento</th>
                        <th>Usuário Gestor</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th>Pagamentos</th>
                        <th className="text-center">Ações</th>
                    </tr>
                </thead>

                <tbody>
                    {contracts.map((contract) => (
                        <tr key={contract.id}>
                            <td>{contract.name}</td>
                            <td>{UtilService.dateFormat(contract.lastPayment)}</td>
                            <td>{contract.managerUserId}</td>
                            <td>{UtilService.curencyFormat(contract.value)}</td>
                            <td>{contract.status ? "Ativo" : "Inativo"}</td>
                            <td>
                                <AddPaymentModal contractId={contract.id} />
                            </td>
                            <td>
                                <div className="w-100 text-center">
                                    <ContractModal contract={contract} users={users} />
                                    <DeleteContractModal id={contract.id} name={contract.name} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}