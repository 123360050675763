import axios from 'axios';
import { LogsApi } from '../apis/LogsApi';
import { FilesApi } from '../apis/FilesApi';

export class UploadService {
    static UploadImages = async (files, oldImagesNames) => {
        const newFilesNames = files.map((file) => file.name)
            .filter((fileName) => !oldImagesNames.includes(fileName));

        if (files.length < 1 || newFilesNames.length < 1) {
            return [];
        }

        const response = await FilesApi.GetPreSignedUrl(newFilesNames);
        const { data } = response;

        if (!data)
            return [];

        const newFiles = files.filter(file => newFilesNames.includes(file.name));
        const urls = data.map((d) => d.url);

        await this.UploadFiles(newFiles, urls);

        return data.map((d) => d.fileName);
    };

    static UploadFiles = async (files, urls) => {
        await Promise.all(urls.map(async (url, index) => {
            const file = files[index];
            const config = {
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            };

            await axios.put(url, file, config)
                .catch(async (error) => {
                    await LogsApi.Post("[WebApp] Error", "UploadFiles", JSON.stringify(error));
                    throw error;
                });
        }));

        return true;
    };
}