import axios from 'axios';
import { getToken } from '../utils/Auth';

const api = axios.create({
    baseURL: window.location.protocol + "//" + window.location.host + "/api/"
});

api.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers.authorization = `Bearer ${token}`;
    }
    config.timeout = 120000;
    return config;
});

export default api;
