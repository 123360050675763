import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Formik, Field, Form } from "formik";

import { UtilService } from '../../../utils/Util';
import { UsersApi } from '../../../apis/UsersApi';
import CreateNotification from '../../Core/Notification/Notification';

export function UserModal(props) {
    const user = { ...props.user };

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const saveUser = async (values) => {
        values = {
            ...user,
            ...values
        }

        let response;
        let message;

        if (user.id) {
            response = await UsersApi.Put(values);
            message = 'Usuário atualizado com sucesso!';
        } else {
            response = await UsersApi.Post(values);
            message = 'Usuário criado com sucesso!';
        }

        if (!response.error) {
            CreateNotification('success', message);
        } else {
            CreateNotification('error', response.errors);
        }
    };

    return (
        <>
            <button type="button" className="btn-custom" onClick={handleShow}>
                {user.id ? 'Editar' : 'Novo'}
            </button>

            <Modal show={show} onHide={handleClose} size="sm" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {user.id ? 'Editar Usuário' : 'Novo Usuário'}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Formik
                        initialValues={{
                            name: user?.name ? user.name : "",
                            email: user?.email ? user.email : "",
                            password: "",
                            status: !UtilService.isEmpty(user) ? user.status : true
                        }}
                        onSubmit={saveUser}
                    >
                        <Form className="mb-3">
                            <div className="form-label-group mb-3">
                                <label htmlFor="name">Nome</label>
                                <Field
                                    autoFocus
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    required
                                />
                            </div>

                            <div className="form-label-group mb-3">
                                <label htmlFor="email">Email</label>
                                <Field
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    required
                                />
                            </div>

                            <div className="form-label-group mb-3">
                                <label htmlFor="password">Senha</label>
                                <Field
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    required={!user.id}
                                />
                            </div>

                            <div className="form-check col-md-3">
                                <label htmlFor="status">
                                    <Field
                                        name="status"
                                        className="form-check-input"
                                        type="checkbox"
                                    />
                                    Ativo
                                </label>
                            </div>

                            <button className="btn-custom w-100" type="submit">
                                {user.id ? 'Atualizar' : 'Criar'}
                            </button>
                        </Form>
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}
