import React, { useState } from 'react';
import ListImages from './components/ListImages';
import CreateNotification from '../Notification/Notification';

export function UploadImages(props) {
    const { files, setFiles } = props;
    const [images, setImages] = useState([...props.images]);

    const insertImage = (e) => {
        const newFiles = e.target.files;

        if (newFiles.length <= 0) return;

        if (files.length + newFiles.length > 30) {
            CreateNotification(
                'error',
                'Quantidade de imagens não permitida! Máximo de 30.',
            );

            return;
        }

        setFiles([...files, ...newFiles]);
        setImages([
            ...images,
            ...Array.from(newFiles).map((file) => {
                return {
                    name: file.name,
                    src: URL.createObjectURL(file),
                };
            }),
        ]);
    };

    const removeImage = (e) => {
        e.preventDefault();
        const { name } = e.target.nextSibling;

        const newFiles = files.filter((it) => it.name !== name);
        setFiles(newFiles);

        const newImages = images.filter((it) => it.name !== name);
        setImages(newImages);
    };

    return (
        <div className="form-label-group">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Imagens</span>
                </div>
                <div id="group-imgs" className="custom-file">
                    <label className="custom-file-label" htmlFor="input-image">
                        Selecionar
                    </label>
                    <input
                        multiple
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        className="custom-file-input"
                        name="input-images"
                        onChange={insertImage}
                    />
                </div>
            </div>

            <ListImages images={images} removeFunction={removeImage} />

            <span>{files.length} imagens selecionadas.</span>
        </div>
    );
}
