export const Categories = {
    1020: 'Apartamento',
    1040: 'Casa',
    1060: 'Aluguel',
    1080: 'Temporada',
    1100: 'Terrenos, sitios e fazendas',
    1120: 'Comércio de Indústria',
};

export const ApartmentType = {
    1: 'Padrão',
    2: 'Cobertura',
    3: 'Duplex/triplex',
    4: 'Kitnet',
    5: 'Loft',
};

export const ApartmentFeatures = {
    1: 'Ar-Condicionado',
    2: 'Academia',
    3: 'Armários embutidos',
    4: 'Varanda',
    5: 'Área de serviço',
    6: 'Churrasqueira',
    7: 'Quarto de empregada',
    8: 'Piscina',
    9: 'Salão de festas',
    10: 'Porteiro 24h'
};

export const HomeType = {
    1: 'Padrão',
    2: 'Casa de vila',
    3: 'Casa de condomínio',
};

export const HomeFeatures = {
    1: 'Ar-Condicionado',
    2: 'Piscina',
    3: 'Armários embutidos',
    4: 'Varanda',
    5: 'Área de serviço',
    6: 'Churrasqueira',
    7: 'Quarto de empregada',
    8: 'Porteiro 24h'
};

export const ReLandType = {
    1: 'Terrenos e lotes',
    2: 'Sítios e chácaras',
    3: 'Fazendas',
    4: 'Outros',
};

export const ReLandFeatures = {
    1: 'Área verde',
    2: 'Casa sede',
    3: 'Pomar',
    4: 'Piscina',
    5: 'Churrasqueira',
    6: 'Poço artesiano',
    7: 'Água encanada',
    8: 'Energia elétrica',
    9: 'Campo de futebol',
    10: 'Acesso asfaltado'
};

export const CommercialType = {
    1: 'Escritório',
    2: 'Galpão/Depósito',
    3: 'Hotel',
    4: 'Fábrica',
    5: 'Garagem/Vaga',
    6: 'Loja',
    7: 'Outros',
};

export const CommercialFeatures = {
    1: 'Garagem',
    2: 'Segurança 24h',
    3: 'Câmeras de segurança',
    4: 'Elevador',
    5: 'Portaria',
    6: 'Acesso para deficientes'
};
