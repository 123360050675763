import api from '../infra/api';

export class PaymentsApi {
    static Post = async (payment) => {
        const response = await api.post('payments', payment).catch((error) => {
            return error.response;
        });

        return response;
    };
}