import '../styles/Ad.css';
import '../../Core/css/buttons.css';

import React from 'react';

import { ProductEditModal } from './ProductEditModal';
import { ProductRemoveModal } from './ProductRemoveModal';

import { UtilService } from '../../../utils/Util';
import DefaultHouseImg from '../../../assets/default-house.png';

export function ProductCard(props) {
    const { product } = props;

    const generateLink = () => {
        const id = `anuncio/${product.id}`;
        const url = window.location.href.replace('visualizar', id);

        UtilService.CopyToClipboard(url);
    };

    return (
        <div className="card adCard" style={{ opacity: product.active ? 1 : 0.5 }}>
            <img
                alt="anuncio"
                width="345 px"
                height="260 px"
                className="card-img-top"
                src={product.images[0] ?? DefaultHouseImg}
            />

            <div className="card-body">
                <div className="card-title h5" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {product.active ? '' : '[Inativo]'} {product.subject}
                </div>

                <p className="card-text">
                    <b>Categoria: </b>
                    {UtilService.ShowCategories(product.category)}
                </p>

                <p className="card-text">
                    <b>Preço:</b> R${' '}
                    {product.price != null ? product.price : '-'}
                </p>

                <p className="card-text">
                    <b>Status: </b>
                    {product.status !== null ? product.status : 'Não definido'}
                </p>

                <p className="card-text">
                    <b>Visualizações: </b>
                    {product.visualizations !== null
                        ? product.visualizations
                        : 0}
                </p>

                <p className="card-text">
                    <b>Super Destaques:</b> {' '}
                    {product.quantitySuperPremium != null ? product.quantitySuperPremium : 0}
                </p>

                <p className="card-text">
                    <b>Destaques:</b> {' '}
                    {product.quantityPremium != null ? product.quantityPremium : 0}
                </p>

                <div className="d-flex justify-content-around">
                    <button
                        className="btn-custom"
                        style={{ width: '120px' }}
                        onClick={generateLink}
                    >
                        Compartilhar
                    </button>

                    <ProductEditModal product={product} />

                    <ProductRemoveModal id={product.id} />
                </div>
            </div>
        </div>
    );
}
