import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-block-ui/style.css';
import 'react-notifications/lib/notifications.css';

// Components
import { Header } from './views/Header/Header';
import { Footer } from './views/Footer/Footer';
import { WebRoutes } from './routes';
import { NotificationContainer } from 'react-notifications';

export default function App() {
    return (
        <BrowserRouter>
            <Header />
            <WebRoutes />
            <Footer />

            <NotificationContainer />
        </BrowserRouter>
    );
}
