import React from 'react';

import { ProductService } from '../../../services/ProductService';

export default function Informations(props) {
    const informations = ProductService.GetAdsInformations(props.products);
    const publications = props.publications;

    return (
        <div className="row mt-4 div-info">
            <div className="card adCard col-md-2 info-card ">
                <div>
                    <b>Anúncios</b>
                </div>
                <span>{informations.total}</span>
            </div>

            <div className="card adCard col-md-2 info-card ">
                <div>
                    <b>Apartamentos</b>
                </div>
                <span>{informations.apartments}</span>
            </div>

            <div className="card adCard col-md-2 info-card ">
                <div>
                    <b>Casas</b>
                </div>
                <span>{informations.homes}</span>
            </div>

            <div className="card adCard col-md-2 info-card ">
                <div>
                    <b>Super Destaques</b>
                </div>
                <span>{informations.quantitySuperPremium}</span>
            </div>

            <div className="card adCard col-md-2 info-card ">
                <div>
                    <b>Destaques</b>
                </div>
                <span>{informations.quantityPremium}</span>
            </div>

            <div className="card adCard col-md-2 info-card ">
                <div>
                    <b>Anúncios no mês</b>
                </div>
                <span>{publications}</span>
            </div>
        </div>
    );
}
