import React, { useState } from 'react';
import BlockUi from 'react-block-ui';

import Modal from 'react-bootstrap/Modal';

import { UsersApi } from '../../../apis/UsersApi';
import CreateNotification from '../../Core/Notification/Notification';

export function DeleteUserModal(props) {
    const [show, setShow] = useState(false);
    const [onRequest, setOnRequest] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const deleteUser = async (e) => {
        e.preventDefault();

        setOnRequest(true);

        const response = await UsersApi.Delete(props.id);

        if (!response.error) {
            CreateNotification('success', 'Usuário deletado com sucesso!');
        } else {
            CreateNotification('error', response.errors);
        }

        setOnRequest(false);
    };

    return (
        <>
            <button className="btn-custom ml-2" onClick={handleShow}>
                Remover
            </button>

            <Modal show={show} onHide={handleClose} size="sm" backdrop="static">
                <BlockUi
                    tag="div"
                    blocking={onRequest}
                    message="Removendo usuário"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Deletar Usuário</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                            <p>
                                Deseja realmente remover o usuário {props.name}?
                            </p>

                            <div className="text-right">
                                <button
                                    className="btn-custom"
                                    onClick={deleteUser}
                                >
                                    Sim
                                </button>

                                <div
                                    className="btn-custom ml-2"
                                    onClick={handleClose}
                                >
                                    Não
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </BlockUi>
            </Modal>
        </>
    );
}
