import React, { useState } from 'react';
import BlockUi from 'react-block-ui';

import Modal from 'react-bootstrap/Modal';

import { OlxApi } from '../../../apis/OlxApi';
import CreateNotification from '../../Core/Notification/Notification';

export function RemoveAdsModal() {
    const state = {};
    const [onRequest, setOnRequest] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleInput = async (e) => {
        e.preventDefault();

        const name = e.target.name;
        state[name] = e.target.value;
    };

    const remove = async (e) => {
        e.preventDefault();

        setOnRequest(!onRequest);

        await OlxApi.RemoveAdByQuantity(state.quantity)
            .then(() => {
                CreateNotification(
                    'success',
                    'Os anúncios serão removidos em breve.',
                );
            })
            .catch((error) => {
                CreateNotification('error', error.message);
            });

        setOnRequest(!onRequest);
    };

    return (
        <div>
            <button
                className="btn-custom"
                style={{ width: '140px' }}
                onClick={handleShow}
            >
                Remover Anúncios
            </button>

            <Modal show={show} onHide={handleClose} size="sm" backdrop="static">
                <BlockUi
                    tag="div"
                    blocking={onRequest}
                    message="Iniciando remoção em massa..."
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Remover Anúncios</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="div-signin">
                            <div className="form-group mb-3">
                                <label htmlFor="quantity">
                                    <p>
                                        <b>Como funciona:</b>
                                    </p>
                                    <p>
                                        Digite a quantidade que deseja remover e
                                        então será removida a quantidade
                                        solicitada dos anúncios mais antigos.
                                    </p>

                                    <p>
                                        Exemplo: Se for digitada a quantidade
                                        1000, os 1000 anúncios mais antigos
                                        serão removidos do Olx ou CanalPro.
                                    </p>
                                </label>

                                <input
                                    type="number"
                                    name="quantity"
                                    min="1"
                                    className="form-control"
                                    onChange={handleInput}
                                />
                            </div>

                            <div className="text-center">
                                <button
                                    className="btn-custom mr-2"
                                    onClick={remove}
                                >
                                    Remover
                                </button>

                                <button
                                    className="btn-custom ml-2"
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </BlockUi>
            </Modal>
        </div>
    );
}
