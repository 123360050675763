export function ProductParameters(props) {
    const product = props.product;

    return (
        <>
            <div className="form-row">
                {/* Immobile Categories */}
                <div className="form-group col-md-6">
                    <label htmlFor="category">Tipo do Imóvel</label>
                    <select
                        className="form-control"
                        id="category"
                        name="category"
                        onChange={props.handleChange}
                        defaultValue={product.category}
                    >
                        <option value="1020">1 - Apartamento</option>
                        <option value="1040">2 - Casa</option>
                        <option value="1060">3 - Aluguel de quartos</option>
                        <option value="1080">4 - Temporada</option>
                        <option value="1100">
                            5 - Terrenos, sítios e fazendas
                        </option>
                        <option value="1120">6 - Comércio e indústria</option>
                    </select>
                </div>

                {/* Begin Product Type */}
                {/* Apartments */}
                <div
                    className={
                        product.category === 1020
                            ? 'form-group col-md-6'
                            : 'form-group col-md-6 d-none'
                    }
                >
                    <label htmlFor="productType">Categoria do Imóvel*</label>
                    <select
                        className="form-control"
                        name="productType"
                        required
                        onChange={props.handleChange}
                        defaultValue={product.productType}
                    >
                        <option value="1">1 - Padrão</option>
                        <option value="2">2 - Cobertura</option>
                        <option value="3">3 - Duplex/Triplex</option>
                        <option value="4">4 - Kitchenette</option>
                        <option value="5">5 - Loft/Studio</option>
                    </select>
                </div>

                {/* Home */}
                <div
                    className={
                        product.category === 1040
                            ? 'form-group col-md-6'
                            : 'form-group col-md-6 d-none'
                    }
                >
                    <label htmlFor="productType">Categoria do Imóvel*</label>
                    <select
                        className="form-control"
                        name="productType"
                        required
                        onChange={props.handleChange}
                        defaultValue={product.productType}
                    >
                        <option value="1">1 - Rua pública</option>
                        <option value="2">2 - Vila</option>
                        <option value="3">3 - Condomínio Fechado</option>
                    </select>
                </div>

                {/* Season */}
                <div
                    className={
                        product.category === 1080
                            ? 'form-group col-md-6'
                            : 'form-group col-md-6 d-none'
                    }
                >
                    <label htmlFor="productType">Categoria do Imóvel*</label>
                    <select
                        className="form-control"
                        name="productType"
                        required
                        onChange={props.handleChange}
                        defaultValue={product.productType}
                    >
                        <option value="1">1 - Apartamento</option>
                        <option value="2">2 - Casa</option>
                        <option value="3">3 - Quarto individual</option>
                        <option value="4">4 - Quarto compartilhado</option>
                        <option value="5">5 - Hotel, hostel e pousada</option>
                        <option value="6">6 - Sítio, fazenda e chácara</option>
                    </select>
                </div>

                {/* Re Land */}
                <div
                    className={
                        product.category === 1100
                            ? 'form-group col-md-6'
                            : 'form-group col-md-6 d-none'
                    }
                >
                    <label htmlFor="productType">Categoria do Imóvel*</label>
                    <select
                        className="form-control"
                        name="productType"
                        required
                        onChange={props.handleChange}
                        defaultValue={product.productType}
                    >
                        <option value="1">1 - Terrenos e lotes</option>
                        <option value="2">2 - Sítios e chácaras</option>
                        <option value="3">3 - Fazendas</option>
                        <option value="4">4 - Outros</option>
                    </select>
                </div>

                {/* Commercial */}
                <div
                    className={
                        product.category === 1120
                            ? 'form-group col-md-6'
                            : 'form-group col-md-6 d-none'
                    }
                >
                    <label htmlFor="productType">Categoria do Imóvel*</label>
                    <select
                        className="form-control"
                        name="productType"
                        required
                        onChange={props.handleChange}
                        defaultValue={product.productType}
                    >
                        <option value="1">1 - Escritório</option>
                        <option value="2">2 - Galpão/Depósito</option>
                        <option value="3">3 - Hotel</option>
                        <option value="4">4 - Fábrica</option>
                        <option value="5">5 - Garagem/Vaga</option>
                        <option value="6">6 - Loja</option>
                        <option value="7">7 - Outros</option>
                    </select>
                </div>
            </div>
            {/* End Product Category */}

            {product.category === 1020 ||
                product.category === 1040 ||
                product.category === 1080 ||
                product.category === 1100 ? (
                <div className="row md-3">
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="rooms">Quantidade de quartos*</label>
                        <select
                            className="form-control"
                            name="rooms"
                            required
                            onChange={props.handleChange}
                            defaultValue={product.rooms}
                        >
                            <option value="0">0 quartos</option>
                            <option value="1">1 quarto</option>
                            <option value="2">2 quartos</option>
                            <option value="3">3 quartos</option>
                            <option value="4">4 quartos</option>
                            <option value="5">5 ou mais quartos</option>
                        </select>
                    </span>

                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="bathrooms">
                            Quantidade de banheiros
                        </label>
                        <select
                            className="form-control"
                            name="bathrooms"
                            onChange={props.handleChange}
                            defaultValue={product.bathrooms}
                        >
                            <option value="0">0 banheiros</option>
                            <option value="1">1 banheiro</option>
                            <option value="2">2 banheiros</option>
                            <option value="3">3 banheiros</option>
                            <option value="4">4 banheiros</option>
                            <option value="5">5 ou mais banheiros</option>
                        </select>
                    </span>
                </div>
            ) : (
                ''
            )}

            <div className="row md-3">
                {product.category !== 1060 && product.category !== 1100 ? (
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="garageSpaces">Vagas na garagem</label>
                        <select
                            className="form-control"
                            name="garageSpaces"
                            onChange={props.handleChange}
                            defaultValue={product.garageSpaces}
                        >
                            <option value="0">0 vagas</option>
                            <option value="1">1 vaga</option>
                            <option value="2">2 vagas</option>
                            <option value="3">3 vagas</option>
                            <option value="4">4 vagas</option>
                            <option value="5">5 ou mais vagas</option>
                        </select>
                    </span>
                ) : (
                    ''
                )}

                {product.category !== 1060 && product.category !== 1080 ? (
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="condominio">Valor do condomínio</label>
                        <input
                            type="number"
                            id="condominio"
                            name="condominio"
                            className="form-control"
                            placeholder="Preço sem centavos - Ex.: 100"
                            min="1"
                            onChange={props.handleChange}
                            defaultValue={product.condominio}
                        />
                    </span>
                ) : (
                    ''
                )}
            </div>
            
            <div className="row md-3">
                {product.category === 1020 || product.category === 1040 ? (
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="constructionYear">Ano de construção</label>
                        <input
                            type="number"
                            id="constructionYear"
                            name="constructionYear"
                            className="form-control"
                            placeholder="Ano de Construção"
                            min="1900"
                            onChange={props.handleChange}
                            defaultValue={product.constructionYear}
                        />
                    </span>
                ) : (
                    ''
                )}

                {product.category === 1020 || product.category === 1040 ? (
                    <span className="form-label-group mb-3 col-md-6">
                        <label htmlFor="iptu">Valor do IPTU</label>
                        <input
                            type="number"
                            id="iptu"
                            name="iptu"
                            className="form-control"
                            placeholder="Preço sem centavos - Ex.: 100"
                            min="1"
                            onChange={props.handleChange}
                            defaultValue={product.iptu}
                        />
                    </span>
                ) : (
                    ''
                )}
            </div>

            {/* Begin Product Features Categories */}
            {/* Apartment Features */}
            <div
                className={
                    product.category === 1020
                        ? 'form-group'
                        : 'form-group d-none'
                }
            >
                <label htmlFor="features">
                    Benefícios do Imóvel - Segure CTRL para selecionar mais de 1
                </label>
                <select
                    multiple
                    className="form-control"
                    name="features"
                    size="8"
                    onChange={props.handleChange}
                    defaultValue={product.category === 1020 ? product.features : []}
                >
                    <option value="1">1 - Ar-Condicionado</option>
                    <option value="2">2 - Academia</option>
                    <option value="3">3 - Armários embutidos</option>
                    <option value="4">4 - Varanda</option>
                    <option value="5">5 - Área de serviço</option>
                    <option value="6">6 - Churrasqueira</option>
                    <option value="7">7 - Quarto de empregada</option>
                    <option value="8">8 - Piscina</option>
                    <option value="9">9 - Salão de festas</option>
                    <option value="10">10 - Porteiro 24h</option>
                </select>
            </div>

            {/* Home Features */}
            <div
                className={
                    product.category === 1040
                        ? 'form-group'
                        : 'form-group d-none'
                }
            >
                <label htmlFor="features">
                    Benefícios do Imóvel - Segure CTRL para selecionar mais de 1
                </label>
                <select
                    multiple
                    className="form-control"
                    name="features"
                    size="8"
                    onChange={props.handleChange}
                    defaultValue={product.category === 1040 ? product.features : []}
                >
                    <option value="1">1 - Ar-Condicionado</option>
                    <option value="2">2 - Piscina</option>
                    <option value="3">3 - Armários embutidos</option>
                    <option value="4">4 - Varanda</option>
                    <option value="5">5 - Área de serviço</option>
                    <option value="6">6 - Churrasqueira</option>
                    <option value="7">7 - Quarto de empregada</option>
                    <option value="8">8 - Porteiro 24h</option>
                </select>
            </div>

            {/* Room Rent Features */}
            <div
                className={
                    product.category === 1060
                        ? 'form-group'
                        : 'form-group d-none'
                }
            >
                <label htmlFor="features">
                    Benefícios do Imóvel - Segure CTRL para selecionar mais de 1
                </label>
                <select
                    multiple
                    className="form-control"
                    name="features"
                    size="8"
                    onChange={props.handleChange}
                    defaultValue={product.category === 1060 ? product.features : []}
                >
                    <option value="1">1 - Armário no quarto</option>
                    <option value="2">2 - Banheiro no quarto</option>
                    <option value="3">3 - Mobiliado</option>
                    <option value="4">4 - Ar condicionado</option>
                    <option value="5">5 - Varanda</option>
                    <option value="6">6 - Aquecimento</option>
                    <option value="7">7 - Internet</option>
                    <option value="8">8 - TV a cabo</option>
                </select>
            </div>

            {/* Season Features */}
            <div
                className={
                    product.category === 1080
                        ? 'form-group'
                        : 'form-group d-none'
                }
            >
                <label htmlFor="features">
                    Benefícios do Imóvel - Segure CTRL para selecionar mais de 1
                </label>
                <select
                    multiple
                    className="form-control"
                    name="features"
                    size="8"
                    onChange={props.handleChange}
                    defaultValue={product.category === 1080 ? product.features : []}
                >
                    <option value="1">1 - Ar condicionado</option>
                    <option value="2">2 - Aquecimento</option>
                    <option value="3">3 - Café da manhã</option>
                    <option value="4">4 - Churrasqueira</option>
                    <option value="5">5 - Estacionamento</option>
                    <option value="6">6 - Fogão</option>
                    <option value="7">7 - Geladeira</option>
                    <option value="8">8 - Internet</option>
                    <option value="9">9 - Lareira</option>
                    <option value="10">10 - Máquina de lavar</option>
                    <option value="11">11 - Permitido animais</option>
                    <option value="12">12 - Piscina</option>
                    <option value="13">13 - Roupa de cama</option>
                    <option value="14">14 - Toalhas</option>
                    <option value="15">15 - TV a cabo</option>
                    <option value="16">16 - Ventilador</option>
                    <option value="17">17 - Varanda/Terraço</option>
                </select>
            </div>

            {/* Re Land Features */}
            <div
                className={
                    product.category === 1100
                        ? 'form-group'
                        : 'form-group d-none'
                }
            >
                <label htmlFor="features">
                    Benefícios do Imóvel - Segure CTRL para selecionar mais de 1
                </label>
                <select
                    multiple
                    className="form-control"
                    name="features"
                    size="8"
                    onChange={props.handleChange}
                    defaultValue={product.category === 1100 ? product.features : []}
                >
                    <option value="1">1 - Área verde</option>
                    <option value="2">2 - Casa sede</option>
                    <option value="3">3 - Pomar</option>
                    <option value="4">4 - Piscina</option>
                    <option value="5">5 - Churrasqueira</option>
                    <option value="6">6 - Poço artesiano</option>
                    <option value="7">7 - Água encanada</option>
                    <option value="8">8 - Energia elétrica</option>
                    <option value="9">9 - Campo de futebol</option>
                    <option value="10">10 - Acesso asfaltado</option>
                </select>
            </div>

            {/* Commercial Features */}
            <div
                className={
                    product.category === 1120
                        ? 'form-group'
                        : 'form-group d-none'
                }
            >
                <label htmlFor="features">
                    Benefícios do Imóvel - Segure CTRL para selecionar mais de 1
                </label>
                <select
                    multiple
                    className="form-control"
                    name="features"
                    size="8"
                    onChange={props.handleChange}
                    defaultValue={product.category === 1120 ? product.features : []}
                >
                    <option value="1">1 - Garagem</option>
                    <option value="2">2 - Segurança 24h</option>
                    <option value="3">3 - Câmeras de segurança</option>
                    <option value="4">4 - Elevador</option>
                    <option value="5">5 - Portaria</option>
                    <option value="6">6 - Acesso para deficientes</option>
                </select>
            </div>
            {/* End Product Features Categories */}
        </>
    );
}
