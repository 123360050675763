import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui'

import * as Yup from 'yup';
import { Formik, Field, Form } from "formik";
import InputMask from 'react-input-mask';
import CreditCardInput from 'react-credit-card-input';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CreateNotification from '../Core/Notification/Notification';

import { UsersApi } from '../../apis/UsersApi';
import { UtilService } from '../../utils/Util';
import { PaymentsApi } from '../../apis/PaymentsApi';
import YupValidations from '../../validators/YupValidations';
import CpfCnpjInput from '../Core/Components/CpfCnpjInput';

export default function Payments() {
    const [remoteIp, setRemoteIp] = useState("");
    const [contract, setContract] = useState({});
    const [customerInfo, setCustomerInfo] = useState({});

    const [pixQrCode, setPixQrCode] = useState(null);
    const [pixPayload, setPixPayload] = useState(null);
    const [bankSlipUrl, setBankSlipUrl] = useState(null);

    const [onRequest, setOnRequest] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setOnRequest(true);

                const response = await UsersApi.GetCustomerInfo();
                const { customerInfo, contract } = response.data;

                setContract(contract);
                setCustomerInfo(customerInfo);
            } catch (error) {
                CreateNotification('error', 'Erro ao buscar as informações do usuário.');
            } finally {
                setOnRequest(false);
            }
        };

        fetchData();

        UtilService.getClientIp().then(remoteIp => {
            setRemoteIp(remoteIp);
        });
    }, []);

    const executePayment = async (values) => {
        setOnRequest(true);

        values.customerInfo.cpfCnpj = UtilService.removeNonNumericCharacters(values.customerInfo.cpfCnpj);
        values.customerInfo.mobilePhone = UtilService.removeNonNumericCharacters(values.customerInfo.mobilePhone);

        if (remoteIp) {
            values.remoteIp = remoteIp;
        } else {
            values.remoteIp = await UtilService.getClientIp();
        }

        if (["CREDIT_CARD", "SUBSCRIPTION"].includes(values.billingType)) {
            const expiryDateSplit = values.creditCard.expiryDate.split("/");
            values.creditCard.expiryMonth = expiryDateSplit[0].trim();
            values.creditCard.expiryYear = expiryDateSplit[1].trim();
            values.creditCard.number = values.creditCard.number.replace(/\s/g, "");
            values.creditCardHolderInfo.cpfCnpj = UtilService.removeNonNumericCharacters(values.creditCardHolderInfo.cpfCnpj);
            values.creditCardHolderInfo.phone = UtilService.removeNonNumericCharacters(values.creditCardHolderInfo.phone);
            values.creditCardHolderInfo.postalCode = UtilService.removeNonNumericCharacters(values.creditCardHolderInfo.postalCode);
        }

        try {
            const response = await PaymentsApi.Post(values);
            if (response.status === 200) {
                const { data } = response.data;
                if (values.billingType === "PIX") {
                    const { pixQrCode } = data;
                    setPixPayload(pixQrCode.payload);
                    setPixQrCode(pixQrCode.encodedImage);
                }
                else if (values.billingType === "BOLETO") {
                    const { bankSlipUrl: urlBoleto } = data;
                    setBankSlipUrl(urlBoleto);
                    window.open(urlBoleto)
                } else {
                    CreateNotification('success', 'Pagamento enviado para processamento!');
                }
            } else {
                const { errors } = response.data;
                errors.forEach(error => CreateNotification('error', error));
            }
        } catch (error) {
            console.log('Error', error);
            CreateNotification('error', 'Ocorreu um erro ao enviar o pagamento. Por favor, contate o administrador.');
        }

        setOnRequest(false);
    };

    const formValidations = Yup.object().shape({
        customerInfo: Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório'),
            cpfCnpj: YupValidations.cpfCnpjValidate(),
            email: YupValidations.emailValidate(),
            mobilePhone: YupValidations.phoneValidate(),
        }),
        billingType: Yup.string().required('O tipo de faturamento é obrigatório')
            .oneOf(['PIX', 'CREDIT_CARD', 'SUBSCRIPTION', 'BOLETO'], 'Tipo de faturamento inválido'),
        creditCard: Yup.object().when('billingType', {
            is: (billingType) => billingType === 'CREDIT_CARD' || billingType === 'SUBSCRIPTION',
            then: () => Yup.object({
                holderName: Yup.string().required('O nome do titular do cartão é obrigatório'),
                number: Yup.string().required('O número do cartão é obrigatório'),
                expiryDate: Yup.string().required('A data de expiração é obrigatória'),
                ccv: Yup.string().required('O código de segurança é obrigatório'),
            }),
            otherwise: () => Yup.object().strip()
        }),
        creditCardHolderInfo: Yup.object().when('billingType', {
            is: (billingType) => billingType === 'CREDIT_CARD' || billingType === 'SUBSCRIPTION',
            then: () => Yup.object({
                cpfCnpj: YupValidations.cpfCnpjValidate(),
                email: YupValidations.emailValidate(),
                postalCode: YupValidations.cepValidate(),
                phone: YupValidations.phoneValidate(),
                name: Yup.string().required('O nome do titular do cartão é obrigatório'),
                addressNumber: Yup.string().required('O número do endereço é obrigatório'),
            }),
            otherwise: () => Yup.object().strip()
        })
    });

    const getButtonLabel = (billingType) => {
        switch (billingType) {
            case 'PIX':
                return 'Gerar QrCode';
            case 'BOLETO':
                return 'Gerar Boleto';
            default:
                return 'Realizar Pagamento';
        }
    };

    return (
        <div className='container'>
            <BlockUi tag="div" blocking={onRequest} message="Aguarde...">
                <div className="card form-custom mt-4">
                    <div className="p-5">
                        <h4 className="text-center mb-4">Pagamentos</h4>

                        <p>
                            <b>Valor a pagar:</b> {contract?.value ? UtilService.curencyFormat(contract.value) : UtilService.curencyFormat(0)}
                        </p>

                        <Formik
                            initialValues={{
                                customerInfo: {
                                    name: customerInfo?.name ? customerInfo.name : '',
                                    cpfCnpj: customerInfo?.cpfCnpj ? customerInfo.cpfCnpj : '',
                                    email: customerInfo?.email ? customerInfo.email : '',
                                    mobilePhone: customerInfo?.mobilePhone ? customerInfo.mobilePhone : '',
                                },
                                billingType: "PIX",
                                creditCard: {
                                    holderName: '',
                                    number: '',
                                    expiryDate: '',
                                    ccv: '',
                                },
                                creditCardHolderInfo: {
                                    name: '',
                                    email: '',
                                    cpfCnpj: '',
                                    postalCode: '',
                                    addressNumber: '',
                                    phone: '',
                                }
                            }}
                            onSubmit={executePayment}
                            enableReinitialize={true}
                            validateOnBlur={true}
                            validateOnMount={true}
                            validationSchema={formValidations}
                        >
                            {({ values, errors, touched, setFieldValue, isValid }) => (
                                <Form className="form-group mb-3">
                                    <section>
                                        <p>
                                            <b>Informações do Cliente</b>
                                        </p>

                                        <div className="row form-group mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="customerInfo.name">
                                                    Nome Completo
                                                </label>

                                                <Field
                                                    name="customerInfo.name"
                                                    className="form-control"
                                                    type="text"
                                                />
                                                {errors.customerInfo?.name && touched.customerInfo?.name ? (
                                                    <div>{errors.customerInfo.name}</div>
                                                ) : null}
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="customerInfo.cpfCnpj">
                                                    CPF/CNPJ
                                                </label>

                                                <Field
                                                    name="customerInfo.cpfCnpj"
                                                    className="form-control"
                                                    component={CpfCnpjInput}
                                                    type="text"
                                                />
                                                {errors.customerInfo?.cpfCnpj && touched.customerInfo?.cpfCnpj ? (
                                                    <div>{errors.customerInfo.cpfCnpj}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="row form-group mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="customerInfo.email">
                                                    Email
                                                </label>

                                                <Field
                                                    name="customerInfo.email"
                                                    className="form-control"
                                                    type="text"
                                                />
                                                {errors.customerInfo?.email && touched.customerInfo?.email ? (
                                                    <div>{errors.customerInfo.email}</div>
                                                ) : null}
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="customerInfo.mobilePhone">
                                                    Telefone
                                                </label>

                                                <Field
                                                    name="customerInfo.mobilePhone"
                                                    type="text"
                                                >
                                                    {({ field }) => (
                                                        <InputMask
                                                            {...field}
                                                            className="form-control"
                                                            mask='(99) 99999-9999'
                                                        />
                                                    )}
                                                </Field>
                                                {errors.customerInfo?.mobilePhone && touched.customerInfo?.mobilePhone ? (
                                                    <div>{errors.customerInfo.mobilePhone}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </section>

                                    <section className='pt-3'>
                                        <p>
                                            Forma de Pagamento
                                        </p>

                                        <Tabs
                                            className="mb-3 tabs-class"
                                            defaultActiveKey="PIX"
                                            onSelect={(billingType) => setFieldValue('billingType', billingType)}
                                        >
                                            <Tab eventKey="PIX" title="Pix">
                                            </Tab>
                                            <Tab eventKey="BOLETO" title="Boleto">
                                            </Tab>
                                            {/* <Tab eventKey="CREDIT_CARD" title="Cartão de Crédito">
                                            </Tab>
                                            <Tab eventKey="SUBSCRIPTION" title="Pagamento Recorrente">
                                            </Tab> */}
                                        </Tabs>

                                        {
                                            (["CREDIT_CARD", "SUBSCRIPTION"].includes(values.billingType) &&
                                                <React.Fragment>
                                                    <p>
                                                        <b>Informações do Títular do Cartão</b>
                                                    </p>
                                                    <hr />

                                                    < div className="row form-group mb-3">
                                                        <div className="col-md-6">
                                                            <label htmlFor="creditCardHolderInfo.name">
                                                                Nome do titular do cartão
                                                            </label>

                                                            <Field
                                                                name="creditCardHolderInfo.name"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            {errors.creditCardHolderInfo?.name && touched.creditCardHolderInfo?.name ? (
                                                                <div>{errors.creditCardHolderInfo.name}</div>
                                                            ) : null}
                                                        </div>

                                                        <div className="col-md-6">
                                                            <label htmlFor="creditCardHolderInfo.cpfCnpj">
                                                                CPF ou CNPJ do titular do cartão
                                                            </label>

                                                            <Field
                                                                name="creditCardHolderInfo.cpfCnpj"
                                                                className="form-control"
                                                                component={CpfCnpjInput}
                                                                type="text"
                                                            />
                                                            {errors.creditCardHolderInfo?.cpfCnpj && touched.creditCardHolderInfo?.cpfCnpj ? (
                                                                <div>{errors.creditCardHolderInfo.cpfCnpj}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group mb-3">
                                                        <div className="col-md-6">
                                                            <label htmlFor="creditCardHolderInfo.email">
                                                                Email do titular do cartão
                                                            </label>

                                                            <Field
                                                                name="creditCardHolderInfo.email"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            {errors.creditCardHolderInfo?.email && touched.creditCardHolderInfo?.email ? (
                                                                <div>{errors.creditCardHolderInfo.email}</div>
                                                            ) : null}
                                                        </div>

                                                        <div className="col-md-6">
                                                            <label htmlFor="creditCardHolderInfo.phone">
                                                                Fone com DDD do titular do cartão
                                                            </label>

                                                            <Field
                                                                name="creditCardHolderInfo.phone"
                                                                type="number"
                                                            >
                                                                {({ field }) => (
                                                                    <InputMask
                                                                        {...field}
                                                                        className="form-control"
                                                                        mask='(99) 99999-9999'
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors.creditCardHolderInfo?.phone && touched.creditCardHolderInfo?.phone ? (
                                                                <div>{errors.creditCardHolderInfo.phone}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group mb-3">
                                                        <div className="col-md-6">
                                                            <label htmlFor="creditCardHolderInfo.postalCode">
                                                                CEP do titular do cartão
                                                            </label>

                                                            <Field
                                                                name="creditCardHolderInfo.postalCode"
                                                                type="text"
                                                            >
                                                                {({ field }) => (
                                                                    <InputMask
                                                                        {...field}
                                                                        className="form-control"
                                                                        mask="99999-999"
                                                                        maskChar=""
                                                                        placeholder="_____-___"
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors.creditCardHolderInfo?.postalCode && touched.creditCardHolderInfo?.postalCode ? (
                                                                <div>{errors.creditCardHolderInfo.postalCode}</div>
                                                            ) : null}
                                                        </div>

                                                        <div className="col-md-6">
                                                            <label htmlFor="creditCardHolderInfo.addressNumber">
                                                                Número do endereço do titular do cartão
                                                            </label>

                                                            <Field
                                                                name="creditCardHolderInfo.addressNumber"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            {errors.creditCardHolderInfo?.addressNumber && touched.creditCardHolderInfo?.addressNumber ? (
                                                                <div>{errors.creditCardHolderInfo.addressNumber}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <p>
                                                        <b>Informações do Cartão de Crédito</b>
                                                    </p>
                                                    <hr />

                                                    <div className="row form-group mb-3">
                                                        <div className="col-md-6">
                                                            <label htmlFor="creditCard.holderName">
                                                                Nome impresso no cartão
                                                            </label>

                                                            <Field
                                                                name="creditCard.holderName"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            {errors.creditCard?.holderName && touched.creditCard?.holderName ? (
                                                                <div>{errors.creditCard.holderName}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <div className="col-md-12 creditCard">
                                                            <Field
                                                                name="creditCard.number"
                                                            >
                                                                {({ field }) => (
                                                                    <CreditCardInput
                                                                        {...field}
                                                                        cardNumberInputProps={{
                                                                            value: values.creditCard.number,
                                                                            onChange: (e) => {
                                                                                setFieldValue('creditCard.number', e.target.value);
                                                                            },
                                                                        }}
                                                                        cardExpiryInputProps={{
                                                                            value: values.creditCard.expiryDate,
                                                                            onChange: (e) => {
                                                                                setFieldValue('creditCard.expiryDate', e.target.value);
                                                                            },
                                                                        }}
                                                                        cardCVCInputProps={{
                                                                            value: values.creditCard.ccv,
                                                                            onChange: (e) => {
                                                                                setFieldValue('creditCard.ccv', e.target.value);
                                                                            },
                                                                        }}
                                                                        customTextLabels={{
                                                                            invalidCardNumber: 'O número do cartão é inválido',
                                                                            expiryError: {
                                                                                invalidExpiryDate: 'A data de validade é inválida',
                                                                                monthOutOfRange: 'O mês de validade deve estar entre 01 e 12',
                                                                                yearOutOfRange: 'O ano de validade não pode estar no passado',
                                                                                dateOutOfRange: 'A data de validade não pode estar no passado'
                                                                            },
                                                                            invalidCvc: 'O código de segurança é inválido',
                                                                            cardNumberPlaceholder: 'Número do cartão',
                                                                            expiryPlaceholder: 'Mês/Ano',
                                                                            cvcPlaceholder: 'CCV',
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                    </section>

                                    <div className="pt-3">
                                        <button type='submit' className="btn-custom" style={{ width: '150px' }} disabled={!isValid || pixQrCode || bankSlipUrl}>
                                            {getButtonLabel(values.billingType)}
                                        </button>
                                    </div>

                                    {
                                        pixQrCode && (
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                <img alt={pixPayload} style={{ width: "300px" }} src={"data:image/png;base64," + pixQrCode} />
                                                <button type="button" className="btn-custom" onClick={() => UtilService.CopyToClipboard(pixPayload)}>
                                                    Copiar código
                                                </button>
                                            </div>
                                        )
                                    }

                                    {
                                        bankSlipUrl && (
                                            <div className="mt-4">
                                                <button type="button" className="btn-custom" onClick={() => window.open(bankSlipUrl, '_blank', 'noopener,noreferrer')}>
                                                    Abrir Boleto
                                                </button>
                                            </div>
                                        )
                                    }
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </BlockUi >
        </div >
    )
}
