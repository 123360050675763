import axios from 'axios';
import CreateNotification from '../views/Core/Notification/Notification';

import {
    ApartmentFeatures,
    ApartmentType,
    Categories,
    CommercialFeatures,
    CommercialType,
    HomeFeatures,
    HomeType,
    ReLandFeatures,
    ReLandType,
} from './Objects';

export class UtilService {
    static dateFormat = (data, onlyDate = true) => {
        if (!data)
            return '';

        const date = new Date(data);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        let formattedDate = `${day}/${month}/${year}`;

        if (!onlyDate) {
            const hour = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            formattedDate += ` - ${hour}:${minutes}`;
        }

        return formattedDate;
    };

    static getNextPayment = (lastPayment, planFormat) => {
        const lastPaymentDate = new Date(lastPayment);
        const nextPaymentDate = new Date(lastPaymentDate);
        nextPaymentDate.setDate(nextPaymentDate.getDate() + planFormat);
        return nextPaymentDate;
    };

    static curencyFormat = (value) => {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    static numberFormat = (number) => {
        return number.toString().padStart(2, '0');
    }

    static sort = (array, property) => {
        array.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1;
            }
            if (a[property] > b[property]) {
                return 1;
            }
            return 0;
        });
    }

    static IntegerValid = (number) => {
        const string = number.toString();

        return !(string.indexOf(',') > 0 || string.indexOf('.') > 0);
    };

    static ShowCategories = (category) => {
        return Categories[category?.toString()];
    };

    static ShowProductType = (category, productType) => {
        switch (category) {
            case 1020:
                return ApartmentType[productType];
            case 1040:
                return HomeType[productType];
            case 1100:
                return ReLandType[productType];
            case 1120:
                return CommercialType[productType];
            default:
                break;
        }
    };

    static ShowFeatures = (category, features) => {
        const details = [];

        switch (category) {
            case 1020:
                features.forEach((feature) => {
                    details.push(ApartmentFeatures[feature]);
                });
                break;
            case 1040:
                features.forEach((feature) => {
                    details.push(HomeFeatures[feature]);
                });
                break;
            case 1100:
                features.forEach((feature) => {
                    details.push(ReLandFeatures[feature]);
                });
                break;
            case 1120:
                features.forEach((feature) => {
                    details.push(CommercialFeatures[feature]);
                });
                break;
            default:
                break;
        }

        return details;
    };

    static CopyToClipboard = (text) => {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        CreateNotification(
            'info',
            'Link copiado para a área de transferência. ' +
            'Para abrir o link clique aqui!',
            null,
            3000,
            () => {
                window.open(text);
            },
        );
    };

    static GetZipCodeInformations = async (zipcode) => {
        if (!zipcode) {
            return null;
        }

        const response = await axios.get(
            `https://viacep.com.br/ws/${zipcode}/json/`,
        );

        return response.data;
    };

    static validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    static validateArpoEmail = (email) => {
        return email.indexOf('@') >= 0;
    };

    static removeDiacritics = (text) => {
        if (!text) return text;

        let normalizedString = text.normalize("NFD");
        let stringBuilder = "";

        for (let i = 0; i < normalizedString.length; i++) {
            let c = normalizedString[i];
            if (c.charCodeAt(0) <= 255) {
                stringBuilder += c;
            }
        }

        return stringBuilder.normalize("NFC");
    }

    static isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    static emailValidate = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    static cepValidate = (cep) => {
        const regex = /^\d{5}-?\d{3}$/;
        return regex.test(cep);
    }

    static phoneValidate = (telefone) => {
        const regex = /^\(?\d{2}\)?[-.\s]?\d{4,5}[-.\s]?\d{4}$/;
        return regex.test(telefone);
    }

    static removeNonNumericCharacters = (text) => {
        return text.replace(/[^\d]+/g, '');
    }

    static cpfValidate = (cpf) => {
        cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

        if (cpf.length !== 11 ||
            cpf === '00000000000' ||
            cpf === '11111111111' ||
            cpf === '22222222222' ||
            cpf === '33333333333' ||
            cpf === '44444444444' ||
            cpf === '55555555555' ||
            cpf === '66666666666' ||
            cpf === '77777777777' ||
            cpf === '88888888888' ||
            cpf === '99999999999') {
            return false;
        }

        let soma = 0;
        let resto;

        for (let i = 1; i <= 9; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }

        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) {
            resto = 0;
        }

        if (resto !== parseInt(cpf.substring(9, 10))) {
            return false;
        }

        soma = 0;

        for (let i = 1; i <= 10; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }

        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) {
            resto = 0;
        }

        if (resto !== parseInt(cpf.substring(10, 11))) {
            return false;
        }

        return true;
    }

    static cnpjValidate = (cnpj) => {
        cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

        if (cnpj.length !== 14 ||
            cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999') {
            return false;
        }

        let tamanho = cnpj.length - 2;
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }

        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== parseInt(digitos.charAt(0))) {
            return false;
        }

        tamanho += 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== parseInt(digitos.charAt(1))) {
            return false;
        }

        return true;
    }

    static getClientIp = async () => {
        const response = await axios.get('https://api.ipify.org?format=json').catch((error) => {
            return error.response;
        });

        return response.data.ip;
    }
}
