import React, {useState} from 'react';
import '../../Core/css/buttons.css';

import Modal from 'react-bootstrap/Modal';

import {ProductForm} from './ProductForm';

export function ProductEditModal(props) {
    const {product} = props;
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <button
                className="btn-custom ml-3"
                style={{width: '80px'}}
                onClick={handleShow}
            >
                Editar
            </button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edição</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ProductForm product={product} />
                </Modal.Body>
            </Modal>
        </>
    );
}
