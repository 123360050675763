import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import CreateNotification from '../../Core/Notification/Notification';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { ProductsApi } from '../../../apis/ProductsApi';

export function ProductRemoveModal(props) {
    const [state, setState] = useState({ id: props.id, reason: '', description: '' });
    const [onRequest, setOnRequest] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleInput = async (e) => {
        const name = e.target.name;
        const inputText = e.target.value;
        const reason = e.target.id;

        if (name === 'reason') {
            setState({ id: props.id, reason, description: '' });
        } else {
            setState({ id: props.id, reason: state.reason, description: inputText });
        }
    };

    const remove = async () => {
        if (!state.reason) {
            CreateNotification('error', 'Necessário selecionar um motivo de remoção!');
            return;
        }

        setOnRequest(true);

        const response = await ProductsApi.Delete(state);

        setOnRequest(false);

        if (!response.error) {
            CreateNotification('success', 'Anúncio removido com sucesso!');
        } else {
            const { errors } = response;
            CreateNotification('error', errors);
        }
    };

    return (
        <div>
            <button
                className="btn-custom ml-3"
                style={{ width: '80px' }}
                onClick={handleShow}
            >
                Excluir
            </button>

            <Modal show={show} onHide={handleClose} size="md" backdrop="static">
                <BlockUi tag="div" blocking={onRequest} message="Removendo o anúncio. Aguarde." >
                    <Modal.Header closeButton>
                        <Modal.Title>Remover Anúncio</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="div-signin">
                            <div className="form-group mb-3">
                                <label htmlFor="quantity">
                                    Por qual motivo deseja excluir esse anúncio?
                                    Ele também será removido do Olx.
                                </label>

                                <div className="required">
                                    <Form.Check
                                        id="sold"
                                        name="reason"
                                        type="radio"
                                        label="Imóvel vendido"
                                        onChange={handleInput}
                                    />

                                    <Form.Check
                                        id="partner-sold"
                                        name="reason"
                                        type="radio"
                                        label="Outro corretor vendeu"
                                        onChange={handleInput}
                                    />

                                    <Form.Check
                                        id="give-up"
                                        name="reason"
                                        type="radio"
                                        label="Desisti da venda"
                                        onChange={handleInput}
                                    />

                                    <Form.Check
                                        id="another-reason"
                                        name="reason"
                                        type="radio"
                                        label="Outro motivo"
                                        onChange={handleInput}
                                    />
                                </div>

                                {state.reason === "another-reason" &&
                                    <Form.Control
                                        rows={3}
                                        name="description"
                                        as="textarea"
                                        placeholder="Descreva o motivo"
                                        className="mt-3"
                                        onChange={handleInput}
                                    />
                                }
                            </div>

                            <div className="text-center">
                                <button
                                    className="btn-custom mr-2"
                                    onClick={remove}
                                >
                                    Excluir
                                </button>

                                <button
                                    className="btn-custom ml-2"
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </BlockUi>
            </Modal>
        </div>
    );
}
