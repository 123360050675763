import api from '../infra/api';

export class UsersApi {
    static Get = async (id) => {
        const response = await api.get(`users/${id}`).catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static GetAll = async () => {
        const response = await api.get('users').catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static GetCustomerInfo = async () => {
        const response = await api.get('users/customer-info').catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static Post = async (user) => {
        const response = await api.post('users', user).catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static Put = async (user) => {
        const response = await api.put('users', user).catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static Delete = async (id) => {
        const response = await api
            .delete(`users?userId=${id}`)
            .catch((error) => {
                return error.response;
            });

        return response.data;
    };

    static AlterPassword = async (password) => {
        const response = await api
            .put('users/alter-password', { password })
            .catch((error) => {
                return error.response;
            });

        return response.data;
    };
}
