export class ProductService {
    static GetAdsInformations = (products) => {
        const informations = { total: products.length };

        const apartments = products.filter((product) => product.category === 1020).length;
        const homes = products.filter((product) => product.category === 1040).length;
        const quantityPremium = products.reduce((accumulated, product) => { return accumulated + product.quantityPremium }, 0);
        const quantitySuperPremium = products.reduce((accumulated, product) => { return accumulated + product.quantitySuperPremium }, 0);

        informations.apartments = apartments;
        informations.homes = homes;
        informations.quantityPremium = quantityPremium;
        informations.quantitySuperPremium = quantitySuperPremium;

        return informations;
    };
}