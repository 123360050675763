import React from 'react';
import { useField } from 'formik';
import InputMask from 'react-input-mask';

const CpfCnpjInput = ({ ...props }) => {
    const [field, meta, helpers] = useField(props.field.name);
    const { setValue } = helpers;
    const { value } = field;

    const handleChange = event => {
        const newValue = event.target.value;
        setValue(newValue);
    };

    const determineMask = () => {
        if (!value || value.length === 0)
            return "999999999999999999";

        if (value.replace(/\D/g, "").length < 12) {
            return "999.999.999-999999";
        } else {
            return "99.999.999/9999-99";
        }
    };

    return (
        <InputMask
            {...field}
            {...props}
            mask={determineMask()}
            maskChar={null}
            onChange={handleChange}
        />
    );
};

export default CpfCnpjInput;
