import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';

import { UserModal } from './components/UserModal';
import { DeleteUserModal } from './components/DeleteUserModal';

import { UtilService } from '../../utils/Util';
import { UsersApi } from '../../apis/UsersApi';

export default function Users() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        GetAllUsers();
    }, []);

    const GetAllUsers = () => {
        UsersApi.GetAll().then((response) => {
            const { data } = response;
            UtilService.sort(data, 'name');
            setUsers(data);
        });
    }

    return (
        <div
            className={
                users.length > 0 ? 'mt-5 container card form-custom' : 'd-none'
            }
        >
            <h3 className="text-center mt-3">Gerenciamento de Usuários</h3>

            <div className='d-flex justify-content-between'>
                <UserModal />

                <span>
                    <b>Quantidade: </b> {users.length}
                </span>
            </div>

            <Table className="mt-2" responsive hover>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th className="text-center">Ações</th>
                    </tr>
                </thead>

                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.status ? "Ativo" : "Inativo"}</td>
                            <td>
                                <div className="w-100 text-center">
                                    <UserModal user={user} />
                                    <DeleteUserModal id={user.id} name={user.name} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
