import api from '../infra/api';

export class LogsApi {
    static Get = async () => {
        const response = await api.get('logs').catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static Post = async (type, description, state) => {
        const response = await api.post('logs', { type, description, state }).catch((error) => {
            return error.response;
        });

        return response.data;
    }
}
