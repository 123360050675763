import React from 'react';

export default function ListImages(props) {
    const {images, removeFunction} = props;

    return (
        <div className="row mt-3">
            {images.map((image) => (
                <div
                    className="col-md-3 mr-5 text-center"
                    key={image.name}
                    id={image.name}
                >
                    <button
                        onClick={removeFunction}
                        className="btn btn-sm btn-outline-secondary mb-1"
                    >
                        Remover
                    </button>

                    <img
                        name={image.name}
                        height="125"
                        width="225"
                        alt="img-anuncio"
                        src={image.src}
                        className="mb-3"
                    />
                </div>
            ))}
        </div>
    );
}
