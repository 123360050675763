import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './Header.css';
import '../Core/css/core.css';
import '../Core/css/buttons.css';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { UsersApi } from '../../apis/UsersApi';
import { RemoveAdsModal } from './components/RemoveAdsModal';
import { GetContract, isAdmin, isUserManager, logout } from '../../utils/Auth';

import LogoArpoImg from '../../assets/Arpo.png';
import { UtilService } from '../../utils/Util';

export function Header(props) {
    const navigate = useNavigate();
    const notDisplayHeader = () => window.location.pathname === '/' || window.location.pathname.indexOf('anuncio') > 0;

    const subscriptionExpired = () => {
        let contract = GetContract();
        let nextPaymentDate = UtilService.getNextPayment(contract.LastPayment, contract.EvaluationPeriod ? 3 : 30);
        let isDefaulting = nextPaymentDate < new Date();

        if (isDefaulting) {
            return UsersApi.Get(contract.ManagerUserId).then(response => {
                const { data } = response;
                contract = data.contract;
                nextPaymentDate = UtilService.getNextPayment(contract.lastPayment, contract.evaluationPeriod ? 3 : 30);
                isDefaulting = nextPaymentDate < new Date();

                if (!isDefaulting) {
                    navigate("/");
                }

                return isDefaulting;
            }).catch(error => {
                console.error("Erro ao buscar os dados do usuário:", error);
                return true;
            });
        }

        return isDefaulting;
    };

    return (
        notDisplayHeader() ? ('') :
            (
                <header>
                    {subscriptionExpired() && (
                        <div className="payment">
                            <p>O pagamento da sua conta expirou. Por favor, realize o pagamento para continuar utilizando nossos serviços.</p>

                            <Link to={{ pathname: 'pagamentos' }}>
                                <button className="payment-button">
                                    Realizar Pagamento
                                </button>
                            </Link>
                        </div>
                    )}

                    <Navbar id="cabecalho" expand="lg">
                        <div className="container">
                            <Navbar.Brand href="/home">
                                <img
                                    id={props.id}
                                    alt="Logo"
                                    src={LogoArpoImg}
                                    style={{ width: '80px' }}
                                />
                                Arpo
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    <Link className="nav-link" to={{ pathname: 'home' }}>
                                        Home
                                    </Link>

                                    <NavDropdown
                                        title="Anúncios"
                                        id="basic-nav-dropdown"
                                    >
                                        <Link
                                            className="dropdown-item"
                                            to={{ pathname: 'inserir' }}
                                        >
                                            Inserir um Novo
                                        </Link>

                                        <Link
                                            className="dropdown-item"
                                            to={{ pathname: 'visualizar' }}
                                        >
                                            Visualizar Publicados
                                        </Link>
                                    </NavDropdown>

                                    <NavDropdown
                                        title="Usuário"
                                        id="basic-nav-dropdown"
                                    >
                                        <Link
                                            className="dropdown-item"
                                            to={{ pathname: 'minha-conta' }}
                                        >
                                            Minha Conta
                                        </Link>

                                        <Link
                                            className="dropdown-item"
                                            to={{ pathname: 'alterar-senha' }}
                                        >
                                            Alterar Senha
                                        </Link>
                                    </NavDropdown>

                                    {
                                        isAdmin() && (
                                            <NavDropdown
                                                title="Admin"
                                                id="basic-nav-dropdown"
                                            >
                                                <Link
                                                    className="dropdown-item"
                                                    to={{ pathname: 'usuarios' }}
                                                >
                                                    Usuários
                                                </Link>

                                                <Link
                                                    className="dropdown-item"
                                                    to={{ pathname: 'contratos' }}
                                                >
                                                    Contratos
                                                </Link>

                                                <Link
                                                    className="dropdown-item"
                                                    to={{ pathname: 'logs' }}
                                                >
                                                    Logs de Execução
                                                </Link>
                                            </NavDropdown>
                                        )
                                    }

                                    {
                                        isUserManager() && (
                                            <Link className="nav-link" to={{ pathname: 'pagamentos' }}>
                                                Pagamentos
                                            </Link>
                                        )
                                    }

                                    <Link className="nav-link" to={{ pathname: 'novidades' }}>
                                        <b>Novidades</b>
                                    </Link>

                                    <Nav.Link href="/" onClick={logout}>
                                        Logout
                                    </Nav.Link>
                                </Nav>

                                <RemoveAdsModal />
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                </header>
            )
    )
}
