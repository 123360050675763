import { UtilService } from '../utils/Util';
import CreateNotification from '../views/Core/Notification/Notification';

export class UserValidator {
    static IsValid(user) {
        if (!user.name) {
            CreateNotification('error', 'Necessário preencher o nome.');
            return false;
        }

        if (!user.email) {
            CreateNotification('error', 'Necessário preencher o email.');
            return false;
        }

        if (user.email) {
            const validEmail = UtilService.validateArpoEmail(user.email);
            if (!validEmail) {
                CreateNotification('error', 'Email inválido.');
                return false;
            }
        }

        if (user.importTime) {
            let error = false;
            const re = /^[\d|,]+$/;

            user.importTime = user.importTime.replace(" ", "");

            if (!re.test(user.importTime)) {
                error = true;
            }

            user.importTime.split(',').forEach((time) => {
                const number = parseInt(time);
                if (number < 0 || number > 23) error = true;
            });

            if (error) {
                CreateNotification('error', 'Horário de publicação inválido. Exemplo: 8,18,21.');
                return false;
            }
        }

        return true;
    }
}
