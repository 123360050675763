import React, { Component } from 'react';
import './Home.css';

// React-Bootstrap
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

import HomeImg from "../../assets/horizonte.png";

export class Home extends Component {
    render() {
        return (
            <div className="overflow-hidden">
                <div className="row">
                    <img
                        src={HomeImg}
                        style={{ width: '100%' }}
                        alt="logo"
                    />
                </div>

                <div className="container mt-4">
                    <Tab.Container
                        id="list-group-tabs-example"
                        defaultActiveKey="#comoFunciona"
                    >
                        <Row>
                            <Col sm={4}>
                                <ListGroup>
                                    <ListGroup.Item action href="#comoFunciona">
                                        Como funciona a ferramenta
                                    </ListGroup.Item>

                                    <ListGroup.Item
                                        action
                                        href="#oQueFazerNaFerramenta"
                                    >
                                        O que posso fazer na ferramenta?
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>

                            <Col sm={8} className="tab-text-responsive">
                                <Tab.Content>
                                    <Tab.Pane eventKey="#comoFunciona">
                                        <span>
                                            A ferramenta <b>Arpo</b> é uma forma
                                            de automatizar suas publicações no
                                            Olx diariamente, basta apenas
                                            cadastrar seus anuncios que eles
                                            serão importados pro olx diariamente
                                            e removidos após um periodo de
                                            tempo.
                                        </span>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#oQueFazerNaFerramenta">
                                        <span>
                                            É possível <b>cadastrar</b>,{' '}
                                            <b>editar</b>, <b>remover</b> e{' '}
                                            <b>visualizar</b> todos os anúncios
                                            que já foram publicados pela
                                            ferramenta.
                                        </span>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        );
    }
}
