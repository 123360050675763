import React, { Component } from 'react';
import './Login.css';

import api from '../../infra/api';
import { login, logout } from '../../utils/Auth';

import Form from 'react-bootstrap/Form';
import LogoArpoImg from '../../assets/Arpo.png';

import CreateNotification from '../Core/Notification/Notification';

export class Login extends Component {
    state = { email: '', password: '', error: '' };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSignIn = async (e) => {
        e.preventDefault();
        const { email, password } = this.state;

        if (!email || !password) {
            this.setState({ error: 'Preencha e-mail e senha para continuar!' });
            return;
        }

        await api
            .post('/users/login', { email, password })
            .then((response) => {
                const { data } = response.data;

                if (response.status === 200) {
                    const { token } = data;
                    login(token);
                    window.location.href = window.location + 'home';
                }
            })
            .catch((error) => {
                const { data } = error.response;
                CreateNotification('error', data.errors);
                this.setState({
                    error: data.errors,
                });
            });
    };

    render() {
        logout();

        return (
            <div className="w-100">
                <div className="text-center logo-login p-3">
                    <img src={LogoArpoImg} alt="logo" />
                    Arpo
                </div>

                <div id="login">
                    <Form className="container vertical-center mt-5">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder="Entre com seu email"
                                onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" className='mt-4'>
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                name="password"
                                type="password"
                                placeholder="Senha"
                                onChange={this.handleChange}
                            />
                        </Form.Group>

                        {this.state.error && <p>{this.state.error}</p>}

                        <div className="text-center mt-4">
                            <button
                                className="btn-custom"
                                onClick={this.handleSignIn}
                            >
                                Entrar
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}
