import Table from 'react-bootstrap/Table';

export default function News() {
    return (
        <div className='mt-5 container card form-custom'>
            <h3 className="text-center mt-3">Novidades</h3>

            <Table className="mt-2" responsive hover>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Tipo</th>
                        <th>Descrição</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>01/10/2024</td>
                        <td>Nova Funcionalidade</td>
                        <td>
                            <p>
                                <b>Pagamentos</b> usando boleto:
                                <br />
                                <ul>
                                    <li>
                                        Você pode pagar sua mensalidade no Arpo diretamente na plataforma, utilizando o Boleto. Basta acessar o menu de pagamentos. Se não visualizar a opção, você pode não ser o usuário gestor da conta, mas não se preocupe - quando o pagamento é feito pelo usuário gestor, a assinatura é aplicada em todas as contas. Simplifique sua vida financeira e aproveite!
                                    </li>
                                </ul>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>31/03/2024</td>
                        <td>Nova Funcionalidade</td>
                        <td>
                            <p>
                                <b>Pagamentos</b> dentro do Arpo:
                                <br />
                                <ul>
                                    <li>
                                        Você pode pagar sua mensalidade no Arpo diretamente na plataforma, utilizando o Pix. Basta acessar o menu de pagamentos. Se não visualizar a opção, você pode não ser o usuário gestor da conta, mas não se preocupe - quando o pagamento é feito pelo usuário gestor, a assinatura é aplicada em todas as contas. Simplifique sua vida financeira e aproveite!
                                    </li>
                                </ul>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>31/03/2024</td>
                        <td>Nova Funcionalidade</td>
                        <td>
                            <p>
                                Alterar <b>imagem</b> de capa na publicação:
                                <br />
                                <ul>
                                    <li>
                                        Agora, ao enviar um anúncio, a imagem de capa pode ser alterada, proporcionando variedade em cada publicação. O sistema escolherá aleatoriamente uma das imagens para a capa, garantindo uma experiência renovada a cada visualização do cliente.
                                    </li>
                                </ul>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>28/02/2024</td>
                        <td>Nova Funcionalidade</td>
                        <td>
                            <p>
                                Compressão <b>automática</b> de imagens:
                                <br />
                                <ul>
                                    <li>
                                        Apresentamos uma nova funcionalidade em nosso sistema: a compressão automática de imagens. Essa atualização visa mitigar os problemas de carregamento de imagens no Canal Pro, que muitas vezes são causados por arquivos com tamanho superior ao permitido (acima de 7 MB). Com essa compressão automática, garantimos uma experiência mais fluida e eficiente ao enviar e visualizar imagens no Canal Pro.
                                    </li>
                                </ul>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>27/02/2024</td>
                        <td>Melhoria</td>
                        <td>
                            <p>
                                Otimização na <b>visualização de anúncios</b>:
                                <br />
                                <ul>
                                    <li>
                                        Realizamos uma otimização na tela de visualização de anúncios, visando agilizar a navegação. Agora, à medida que o usuário rola a página, mais anúncios são exibidos em tempo real, proporcionando uma experiência mais dinâmica e eficiente.
                                    </li>
                                </ul>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>01/02/2024</td>
                        <td>Nova Funcionalidade</td>
                        <td>
                            <p>
                                Indicador de anúncio <b>inativo</b>:
                                <br />
                                <ul>
                                    <li>
                                        A partir de agora, os anúncios inativos serão apresentados de forma transparente, acompanhados da etiqueta "[Inativo]", proporcionando uma identificação mais clara e rápida para os usuários.
                                    </li>
                                </ul>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>27/01/2024</td>
                        <td>Melhoria</td>
                        <td>
                            <p>
                                <b>Novos campos</b> no cadastro de anúncios:
                                <br />
                                <ul>
                                    <li>
                                        <b>Ativo</b>: Introduzimos um novo campo que possibilita a desativação de um anúncio. Dessa forma, ele não será publicado, eliminando a necessidade de remoção manual.
                                    </li>
                                    <li>
                                        <b>Ano de Construção</b>: Adicionamos um campo destinado ao ano de construção do imóvel. Esta informação é opcional.
                                    </li>
                                    <li>
                                        <b>IPTU</b>: Incluímos um campo para o valor do Imposto Predial e Territorial Urbano (IPTU) do imóvel. Esta informação também é opcional.
                                    </li>
                                </ul>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}