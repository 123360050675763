import React from 'react';
import { Navigate, Routes, Route, Outlet } from 'react-router-dom';

import { Login } from './views/Login/Login';
import { Home } from './views/Home/Home';
import { NewProduct } from './views/Catalog/NewProduct';
import { ViewProducts } from './views/Catalog/ViewProducts';

import MyAccount from './views/Account/MyAccount';
import News from './views/News/News';

import Users from './views/Users/Users';
import Contracts from './views/Contracts/Contracts';
import PublicAd from './views/PublicAd/PublicAd';
import LogSystem from './views/LogSystem/LogSystem';
import AlterPassword from './views/Account/AlterPassword';
import Payments from './views/Payments/Payments';

import { isAuthenticated } from './utils/Auth';

const PrivateRoute = () => {
    const auth = isAuthenticated();
    return auth ? <Outlet /> : <Navigate to="/" />;
}

export function WebRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Login />} />

            <Route exact path="/home" element={<PrivateRoute />}>
                <Route exact path="/home" element={<Home />} />
            </Route>

            <Route exact path="/novidades" element={<PrivateRoute />}>
                <Route exact path="/novidades" element={<News />} />
            </Route>

            {/* Ads Routes begin */}
            <Route exact path="/inserir" element={<PrivateRoute />}>
                <Route exact path="/inserir" element={<NewProduct />} />
            </Route>

            <Route exact path="/visualizar" element={<PrivateRoute />}>
                <Route exact path="/visualizar" element={<ViewProducts />} />
            </Route>
            {/* Ads Routes End */}

            {/* User Routes begin */}
            <Route exact path="/minha-conta" element={<PrivateRoute />}>
                <Route exact path="/minha-conta" element={<MyAccount />} />
            </Route>

            <Route exact path="/alterar-senha" element={<PrivateRoute />}>
                <Route exact path="/alterar-senha" element={<AlterPassword />} />
            </Route>
            {/* User Routes End */}

            {/* Manager User Routes begin */}
            <Route exact path="/usuarios" element={<PrivateRoute />}>
                <Route exact path="/usuarios" element={<Users />} />
            </Route>
            {/* Manager User Routes end */}

            {/* Logs Routes begin */}
            <Route exact path="/logs" element={<PrivateRoute />}>
                <Route exact path="/logs" element={<LogSystem />} />
            </Route>
            {/* Logs Routes end */}

            {/* Contracts Routes begin */}
            <Route exact path="/contratos" element={<PrivateRoute />}>
                <Route exact path="/contratos" element={<Contracts />} />
            </Route>
            {/* Contracts Routes end */}

            {/* Payments Routes begin */}
            <Route exact path="/pagamentos" element={<PrivateRoute />}>
                <Route exact path="/pagamentos" element={<Payments />} />
            </Route>
            {/* Payments Routes end */}

            {/* PublicAd Routes begin */}
            <Route exact path="/anuncio/:id" element={<PublicAd />} />
            {/* PublicAd Routes end */}

            <Route path="*"
                element={() => (
                    <h1 className="container text-center mt-4">
                        Page not found
                    </h1>
                )}
            />
        </Routes>
    );
}
