import api from '../infra/api';

export class ProductsApi {
    static Get = async () => {
        const response = await api.get('products').catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static Post = async (product) => {
        const response = await api.post('products', product).catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static Put = async (product) => {
        const response = await api.put('products', product).catch((error) => {
            return error.response;
        });

        return response.data;
    };

    static Delete = async (state) => {
        const response = await api
            .delete(
                `products?productId=${state.id}&reason=${state.reason}&description=${state.description}`,
            )
            .catch((error) => {
                return error.response;
            });

        return response.data;
    };

    static GetPublicAd = async (id) => {
        const response = await api
            .get(`products/${id}`)
            .catch((error) => {
                return error.response;
            });

        return response.data;
    };
}
