import React, {Component} from 'react';

export class Footer extends Component {
    render() {
        return (
            <footer className="page-footer font-small unique-color-dark mt-5">
                <div className="container">
                    <hr/>
                    <ul className="list-unstyled list-inline text-center">
                        <li className="list-inline-item">
                            <h5 className="mb-0">
                                Arpo - Automação rápida para Olx
                            </h5>
                        </li>
                    </ul>
                </div>

                <div className="footer-copyright text-center mb-3">
                    © Copyright 2021. Todos os direitos reservados.
                </div>
            </footer>
        );
    }
}
